
import Vue from 'vue';
import gradstop from 'gradstop';
import utils from '../../../../../util';
import MapComponent from '@point/map-component';
import ERRORS from '../../../../../errors';

let polygonData = {};
let delayingMap = null;

export default Vue.extend({
  inheritAttrs: false,
  props: [
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'sectionConfig',
    'componentConfig',
    'config',
    'hasRequiredData',
    'hasOverlay',
    'hasMapData',
  ],
  data: (): {
    mapReady: boolean;
    fullyRendered: number;
    hoveredGeo: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    dmaMarketsHash: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    campaignDetails: object | null;
    renderDots: boolean;
    tooltipTitle: string | null;
    tooltipContent: string | null;
    exportBackgroundImage: string | null;
    mapId: string | null;
  } => ({
    mapReady: false,
    campaignDetails: null,
    fullyRendered: 0,
    renderDots: true,
    tooltipTitle: null,
    tooltipContent: null,
    exportBackgroundImage: null,
    mapId: null,
    hoveredGeo: null,
    dmaMarketsHash: {},
  }),
  components: { MapComponent },
  mounted() {
    setTimeout(this.start, 1000);
  },
  methods: {
    setXportingState(): boolean {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map) return;
      const exportState = this.$store.state.customer?.dashxExportState?.[this.componentConfig?.cid];
      const currentZoom = exportState?.zoomLevel || map.GetZoom();
      if (exportState?.center) {
        map.FlyTo(exportState?.center, currentZoom, { animate: false });
        return true;
      }
      return false;
    },
    saveExportState(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map) return;
      const zoomLevel = map.GetZoom();
      const center = map.GetCenter();
      utils.updateExportState(this.componentConfig.cid, 'zoomLevel', zoomLevel);
      utils.updateExportState(this.componentConfig.cid, 'center', center);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    flattenGeoData(data: any, type: string): any[] {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let allGeos: any[] = [];

      // do not include data for disabled product
      if (!this.disabledProducts?.includes(type)) {
        if (data[type]?.GeoList) {
          const geos = data[type].GeoList;
          if (Array.isArray(geos) && geos.length > 0) {
            allGeos = allGeos.concat(geos);
          } else if (geos.GeoParamKey) {
            allGeos.push(geos);
          }
        } else if (data[type]?.DMAList) {
          const dmas = data[type].DMAList;
          if (Array.isArray(dmas) && dmas.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dmas.forEach((dma: any) => {
              if (dma.ZipRate && Array.isArray(dma.ZipRate)) {
                allGeos = allGeos.concat(dma.ZipRate);
              } else if (dma.ZipRate && dma.ZipRate.Zipcode) {
                allGeos.push(dma.ZipRate);
              } else if (dma.DMAName) {
                allGeos.push(dma);
              }
            });
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return allGeos.map((z: any) => ({ ...z, tactic: type }));
    },
    fetchPolygons(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const geoData: any[] = this.mergeSummaryApiGeoData();
      const mergedGeos = Object.values(polygonData);
      if (!mergedGeos || geoData.length === 0 || mergedGeos.length === 0) {
        // eslint-disable-next-line no-console
        console.log('no summary polygons, fallback for summary map removed');
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const geoKeys: any = {};
      let hasLatLongs = false;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mergedGeos.forEach((geo: any) => {
        if (!geoKeys[geo.key]) {
          geoKeys[geo.key] = [];
        }
        if (geoKeys[geo.key].length < 500) {
          if (!geo.id) {
            return;
          }
          if (typeof geo.id === 'string' && geo.id.length > 0) {
            if (geo.id === 'null') {
              return;
            }
          }
          geoKeys[geo.key].push(geo.id);
          if (!hasLatLongs && typeof geo.lat === 'number') {
            hasLatLongs = true;
          }
          if (geo.outerMarket) {
            if (!geoKeys[geo.outerMarket.GeoParamKey]) {
              geoKeys[geo.outerMarket.GeoParamKey] = [];
            }
            geoKeys[geo.outerMarket.GeoParamKey].push(geo.outerMarket.GeoParamVal);
          }
        }
      });

      if (Object.keys(geoKeys).length === 0) {
        // eslint-disable-next-line no-console
        console.log('no geo data', geoKeys, this.dataSource);
        return;
      }

      const hasDMAs = !!geoKeys['dmacodes']; // if we have DMAs, we want to load their polygons

      if (this.isExporting && !hasDMAs && hasLatLongs) {
        this.renderDots = true;
        this.renderPolygons({ clear: true, doneRendering: true, beforePolygons: true });
        return;
      }

      if (!this.isExporting && hasLatLongs) {
        this.renderDots = true;
        this.renderPolygons({ clear: true, doneRendering: false, beforePolygons: true });
      }

      this.$store
        .dispatch('getPolygons', geoKeys)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          if (!data) {
            // eslint-disable-next-line no-console
            console.log('getPolygons', 'no polygon response');
            // this.$store.dispatch('showError', 'no polygons');
          }

          const map = this.$refs[this.mapCacheKey]?.Get();
          let hasPolygons = false;
          if (map) {
            Object.keys(data).forEach((k: string) => {
              const poly = data[k];
              if (k === 'LocationNotFound') {
                if (!this.isExporting) {
                  // eslint-disable-next-line no-console
                  console.log('polygons not found', poly);
                }
                return;
              }
              if (poly.WKT_P100) {
                hasPolygons = true;
                switch (poly.ITYPE) {
                  case 'DMA':
                    {
                      const key = `dmacodes-${poly.DMA_CODE}`;
                      if (!polygonData[key]) {
                        // eslint-disable-next-line no-console
                        console.log('received polygon data for unexpected geo', poly);
                        break;
                      }
                      polygonData[key].poly = { key: k, ...poly };
                    }
                    break;
                  case 'ZIP':
                    {
                      const key = `zips-${k}`;
                      if (!polygonData[key]) {
                        // eslint-disable-next-line no-console
                        console.log('received polygon data for unexpected geo', poly);
                        break;
                      }
                      polygonData[key].poly = { key: k, ...poly };
                    }
                    break;
                  case 'STAT':
                    {
                      const key = `states-${k}`;
                      if (!polygonData[key]) {
                        // eslint-disable-next-line no-console
                        console.log('received polygon data for unexpected geo', poly);
                        break;
                      }
                      polygonData[key].poly = { key: k, ...poly };
                    }
                    break;
                  case 'CITY':
                    {
                      const key = `cities-${k}`;
                      if (!polygonData[key]) {
                        // eslint-disable-next-line no-console
                        console.log('received polygon data for unexpected geo', poly);
                        break;
                      }
                      polygonData[key].poly = { key: k, ...poly };
                    }
                    break;
                  case 'OUTERMARKET':
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Object.values(polygonData).forEach((geo: any) => {
                      if (geo.outerMarket?.GeoParamVal === k) {
                        geo.outerMarket.poly = { key: k, ...poly };
                      }
                    });
                    break;
                  default:
                    // eslint-disable-next-line no-console
                    console.log('not handled polygon ITYPE', poly);
                    break;
                }
              }
            });
            if (!hasPolygons) {
              // eslint-disable-next-line no-console
              console.log('getPolygons', ERRORS.NO_POLYGONS);
              // this.$store.dispatch('showError', ERRORS.NO_POLYGONS);
              return;
            }
            this.renderPolygons({ clear: true, doneRendering: true });
          }
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
    start(): void {
      this.fetchPolygons();
      setTimeout(() => {
        this.campaignDetails = utils.adDataForKey(this, this.componentConfig.detailsSource);
      }, 10);
      setTimeout(() => {
        if (!this.hasRequiredData) {
          this.$emit('rendered', { empty: true });
        }
      }, 10);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mergeSummaryApiGeoData(): any[] {
      polygonData = {};
      let geos = [];
      const adData = this.summaryData;

      if (adData) {
        geos = geos.concat(
          this.flattenGeoData(adData, 'DISPLAY'),
          this.flattenGeoData(adData, 'PREROLL'),
          this.flattenGeoData(adData, 'OTT'),
          this.flattenGeoData(adData, 'VIDEO'),
          this.flattenGeoData(adData, 'GAM'),
          this.flattenGeoData(adData, 'GAMVIDEO'),
          this.flattenGeoData(adData, 'GAMDISPLAY'),
          this.flattenGeoData(adData, 'LINEAR'),
          this.flattenGeoData(adData, 'SEM'),
          this.flattenGeoData(adData, 'BROADCAST'),
          this.flattenGeoData(adData, 'SOCIAL'),
          this.flattenGeoData(adData, 'TRUGEOFENCE'),
        );

        const RSNOuterMarkets = {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        geos.forEach((geo: any) => {
          if (geo.Type !== 'OuterMarket') {
            return;
          }
          const marketKey = `${geo.name} | ${geo.League} | ${geo.Team}`;
          if (RSNOuterMarkets[marketKey]) {
            // eslint-disable-next-line no-console
            console.log('duplicate outer market', geo);
          }
          RSNOuterMarkets[marketKey] = geo;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        geos.forEach((geo: any) => {
          if (geo.Type === 'OuterMarket') {
            return;
          }
          let key, val;
          if (geo.GeoParamKey && geo.GeoParamVal) {
            key = `${geo.GeoParamKey}-${geo.GeoParamVal}`;
            val = geo;
          } else if (geo.Zipcode) {
            key = `zips-${geo.Zipcode}`;
            val = { GeoParamKey: 'zips', GeoParamVal: geo.Zipcode, ...geo };
          } else if (geo.DMAName) {
            key = `dmacodes-${geo.DMA}`;
            val = { GeoParamKey: 'dmacodes', GeoParamVal: geo.DMA, name: geo.DMAName, ...geo };
          } else {
            // eslint-disable-next-line no-console
            console.log('geo without Zipcode or GeoParamKey/GeoParamVal', geo);
            return;
          }
          if (val.name === 'Other' || val?.Type?.toLowerCase() === 'unknown') {
            return;
          }
          if (!polygonData[key]) {
            polygonData[key] = {
              key: val.GeoParamKey,
              id: val.GeoParamVal,
              name: val.name,
              uid: key,
              score: 0,
              geos: [],
              teams: [],
              legacy: [],
              table: { headers: [], data: [] },
              outerMarket: null,
            };
          }
          polygonData[key].geos.push(val);
          if (geo.Lat) {
            polygonData[key].lat = parseFloat(geo.Lat);
          }
          if (geo.Long) {
            polygonData[key].lon = parseFloat(geo.Long);
          }
          if (geo.Impressions && typeof geo.Impressions === 'number') {
            polygonData[key].score += geo.Impressions;
          }
          if (geo.GeoParamKey === 'dmacodes') {
            if (geo.League || geo.Team || geo.RSN) {
              const marketKey = `${geo.name} | ${geo.League} | ${geo.Team}`;
              const outerMarket = RSNOuterMarkets[marketKey];
              if (outerMarket) {
                polygonData[key].outerMarket = outerMarket;
              }
              polygonData[key].teams.push(geo);
            } else {
              polygonData[key].legacy.push(geo);
            }
          }
        });
      }
      return geos;
    },
    debounceMapMoved(): void {
      clearTimeout(this.onMapMovedTimer);
      this.saveExportState();
      this.onMapMovedTimer = setTimeout(() => {
        const map = this.$refs[this.mapCacheKey]?.Get();
        if (!map || !map.host || !map.leaflet) {
          return;
        }
        const zoomLevel = map.GetZoom();
        const newVal = !(zoomLevel && zoomLevel > 9);
        if (newVal !== this.renderDots) {
          this.renderDots = newVal;
          this.renderPolygons({ clear: true });
        }
      }, 500);
    },
    renderPolygons(
      { clear, redrawing, doneRendering, beforePolygons } = {
        clear: false,
        redrawing: false,
        doneRendering: false,
        beforePolygons: false,
      },
    ): void {
      // console.log('render polygons', this.fullyRendered, { clear, redrawing, doneRendering, beforePolygons });

      const dmaGradient = this.Theme?.polygons?.light?.dmaGradient;
      if (!this.mapReady || !dmaGradient) {
        if (delayingMap) {
          clearTimeout(delayingMap);
        }
        delayingMap = setTimeout(() => {
          delayingMap = null;
          this.renderPolygons({ clear, redrawing, doneRendering, beforePolygons });
        }, 250);
        return;
      }

      if (delayingMap) {
        return;
      }

      if (doneRendering && this.fullyRendered === 0) {
        this.fullyRendered = 1;
      }

      const gradient = gradstop({
        stops: 101,
        inputFormat: 'hex',
        colorArray: dmaGradient || ['#B1C5CE', '#03A9F4', '#01579B'],
      });
      const map = this.$refs[this.mapCacheKey]?.Get();

      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      // console.log('render zip polygons', clear, redrawing);
      if (clear) {
        map.ClearMap();
      }

      let hasData = false;
      let hideZeros = !!this.componentConfig.hideZeros;
      let maxPolygonLimit = 100;
      if (this.componentConfig.layerLimit && this.componentConfig.layerLimit > 0) {
        maxPolygonLimit = this.componentConfig.layerLimit;
      }

      let useDots = this.renderDots;
      const zoomLevel = map.GetZoom();
      if (zoomLevel && zoomLevel > 9) {
        useDots = false;
        maxPolygonLimit = 200;
        hideZeros = false;
      }

      if (!useDots) {
        hideZeros = false;
      } else if (maxPolygonLimit < 500) {
        // we can show more dots than polygons
        maxPolygonLimit = 500;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let polygons: any[] = Object.values(polygonData);
      polygons.sort((a, b) => (a.score > b.score ? 1 : b.score > a.score ? -1 : 0));
      if (hideZeros) {
        // polygons = polygons.filter(a => a.score);
      }

      // set max polygon to 1000 to release DASH-5123 before big map refactor DASH-5059
      // Apply different limits for exporting using old method (!isPrinting)
      maxPolygonLimit = this.isExporting && !this.isPrinting ? 100 : 1000;

      if (polygons.length > maxPolygonLimit) {
        polygons = polygons.slice(-1 * maxPolygonLimit);
        if (this.componentConfig.legend) {
          this.legend = this.componentConfig.legend + ` (top ${maxPolygonLimit})`;
        }
      } else if (this.componentConfig.legend) {
        this.legend = this.componentConfig.legend;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let topScoringGeos: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const topScoringLayers: any[] = [];
      if (
        this.componentConfig.zoomToTopNPercents &&
        this.componentConfig.zoomToTopNPercents > 0 &&
        this.componentConfig.zoomToTopNPercents < 100
      ) {
        const add = (acc, x) => acc + x.score;
        const total = polygons.reduce(add, 0);
        const threshold = (total * this.componentConfig.zoomToTopNPercents) / 100.0;
        let i = 0;
        let cutScoreAt = 0;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        polygons.forEach((k: any) => {
          if (topScoringGeos.length > 2 && i > threshold && cutScoreAt && k.score < cutScoreAt) {
            return;
          }
          i += k.score;
          cutScoreAt = k.score;
          topScoringGeos.push({ s: k.score, k: k.uid });
        });
        // to be fair, remove all zips that have the cutting score
        const fair = topScoringGeos.filter(x => x.s > cutScoreAt);
        if (fair.length > 2) {
          // topScoringGeos = fair;
        }
        topScoringGeos = topScoringGeos.map(x => x.k);
        // console.log('topN', polygons.length, topScoringZips.length, cutScoreAt);
      }

      let maxScore = 1;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        if (p.score > maxScore) {
          maxScore = p.score;
        }
      });

      // sort polygons by their zIndex, to render them in the right order
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let sorted: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        // try to never show dots for states and DMAs
        // order layers: high score polygons *below* low score polygons *below* low score dots *below* high score dots
        let score = Math.floor((1000.0 * p.score) / maxScore);
        p.score1000 = score;
        p.score100 = Math.floor(score / 10.0);
        p.useDot = false;
        switch (p.key) {
          case 'states':
            score = 2000 - score;
            break;
          case 'dmacodes':
            score = 3000 - score;
            break;
          case 'cities':
            if (useDots) {
              p.useDot = true;
              score = 4000 + score;
            } else {
              score = 4000 - score;
            }
            break;
          case 'zips':
            // cities and zip share the same score offset, as they can be smaller/bigger than other cities/zips
            if (useDots) {
              p.useDot = true;
              score = 4000 + score;
            } else {
              score = 4000 - score;
            }
            break;
          default:
            score = score + 5000;
            break;
        }
        sorted.push({ s: score, p });
      });
      sorted = sorted.sort((a, b) => (a.s > b.s ? 1 : b.s > a.s ? -1 : 0));
      polygons = sorted.map(kv => kv.p);

      const outerMarketsLayer = map.CreateLayer('outerMarket');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        if (p.outerMarket?.poly?.WKT_P100) {
          let hovered, layerPopup;
          const om = p.outerMarket;
          const layer = map.AddToLayer(outerMarketsLayer);
          // console.log('rendering polygon', key);
          map.SetWKT(layer, p.outerMarket.poly.WKT_P100, true);
          layer.setStyle(this.Theme?.polygons?.light?.outerMarket);
          this.dmaMarketsHash[om.GeoKey] = layer;
          layer
            .on('mouseover', () => {
              this.hoveredGeo = om;
              if (!layer) {
                return;
              }
              hovered = true;
              layer.setStyle(this.Theme?.polygons?.light?.outerMarketHover);
              const lat = parseFloat(p.lat);
              const lon = parseFloat(p.lon);
              let latlon;
              if (!isNaN(lat) && !isNaN(lon)) {
                latlon = [lat, lon];
              }
              if (!latlon) {
                return;
              }
              this.tooltipTitle = `Outer Market: ${om.name} ${om.League} ${om.Team}`;
              this.tooltipContent = `
                <div class="tooltipContent">
                  <div class="tooltipContentLine">
                    <span class="grey--text font-weight-medium">Impressions:
                      <span class="primary--text font-weight-bold ma-0">
                      ${utils.formatNumberWithCommas(om.Impressions)}
                      </span>
                    </span>
                  </div>
                </div>`;
              p.tooltipTimeout = setTimeout(() => {
                if (!hovered) {
                  return;
                }
                const content = this.$refs.mapToolTip;
                if (!content) {
                  layerPopup = null;
                  return;
                }
                layerPopup = map.CreatePopup(content, latlon, {
                  closeButton: false,
                  offset: [2, 4],
                });
              }, 10);
            })
            .on('mouseout', () => {
              if (!layer) {
                return;
              }
              hovered = false;
              setTimeout(() => {
                if (hovered) {
                  return;
                }
                clearTimeout(p.tooltipTimeout);
                layer.setStyle(this.Theme?.polygons?.light?.outerMarket);
                map.ClosePopup(layerPopup);
                layerPopup = null;
              }, 50);
            });
        }
      });

      const geosLayer = map.CreateLayer('geos');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        if (!p.geos || !Array.isArray(p.geos) || p.geos.length === 0) {
          // eslint-disable-next-line no-console
          console.log('zipPoly no data', p);
          return;
        }
        let layer, style, hoverStyle, color, persistentTooltip;
        if (p.useDot || !p.poly?.WKT_P100) {
          if (isNaN(p.lat) || isNaN(p.lon) || p.lat === 0 || p.lon === 0) {
            // console.log('geoData no lat/lon', p);
            return;
          }
          layer = map.AddDivToLayer({ className: 'fenceIcon', zIndexOffset: p.score }, p.lat, p.lon, geosLayer);
          color = gradient[p.score100];
          if (layer._icon) {
            layer._icon.style.background = color;
          } else {
            setTimeout(() => {
              if (layer._icon) {
                layer._icon.style.background = color;
              }
            }, 200);
          }
          hasData = true;
        } else {
          layer = map.AddToLayer(geosLayer);
          map.SetWKT(layer, p.poly.WKT_P100, true);

          color = gradient[p.score100];
          const color1 = gradient[1];
          const color50 = gradient[50];
          const color99 = gradient[99];

          style = {
            ...this.Theme?.polygons?.light?.dma,
            color: color50,
            fillColor: color,
            weight: 1,
            fillOpacity: 0.3,
          };

          hoverStyle = {
            ...this.Theme?.polygons?.light?.dmaHover,
            color: color1,
            fillColor: color99,
            weight: 5,
            fillOpacity: 0.7,
          };
          layer.setStyle(style);
        }

        if (!layer) {
          // eslint-disable-next-line no-console
          console.error('failed to create map layer');
          return;
        }
        if (topScoringGeos.includes(p.uid)) {
          topScoringLayers.push(layer);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let layerPopup: any = null;
        let hovered = false;
        layer
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mouseover', (e: any) => {
            this.hoveredGeo = p;
            if (!layer) {
              return;
            }
            if (layer._icon) {
              // layer._icon.style.opacity = 1;
            } else if (layer.setStyle) {
              layer.setStyle(hoverStyle);
            }

            let latlon = e.latlng;
            if (p.useDot) {
              // use the dot's center
              if (!isNaN(p.lat) && !isNaN(p.lon)) {
                latlon = [p.lat, p.lon];
              }
            }
            hovered = true;
            this.tooltipTitle = null;
            this.tooltipContent = null;
            if (layer.setStyle) {
              layer.setStyle(hoverStyle);
            }
            if (Array.isArray(p.teams) && p.teams.length > 0) {
              persistentTooltip = true;
              if (!isNaN(p.lat) && !isNaN(p.lon)) {
                latlon = [p.lat, p.lon];
              }
              p.table.headers = [
                { text: 'Team', value: 'Team' },
                { text: 'League', value: 'League' },
                { text: 'Imps', value: 'Impressions' },
              ];
              p.table.data = p.teams.map(x => ({
                key: x.GeoKey,
                Team: x.Team,
                League: x.League,
                Impressions: utils.formatNumberWithCommas(x.Impressions),
              }));
              if (Array.isArray(p.legacy) && p.legacy.length > 0) {
                const impressions = p.legacy.reduce((sum, geo) => sum + geo.Impressions, 0);
                p.table.data.push({
                  key: 'legacy',
                  Team: 'n/a',
                  League: 'n/a',
                  Impressions: utils.formatNumberWithCommas(impressions),
                });
              }
            } else {
              const first = p.geos[0];
              switch (p.key) {
                case 'zips':
                  this.tooltipTitle = `Zip Code: ${p.id}`;
                  break;
                case 'dmacodes':
                  this.tooltipTitle = `DMA: ${first.name}`;
                  break;
                case 'states':
                  this.tooltipTitle = `${first.name}`;
                  break;
                case 'cities':
                  this.tooltipTitle = `${first.name}`;
                  break;
                default:
                  this.tooltipTitle = `${p.id}`;
                  // eslint-disable-next-line no-console
                  console.error('unhandled polygon type', p);
                  break;
              }
              this.tooltipContent = `<div class="tooltipContent">`;

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              p.geos.forEach((x: any) => {
                let key, val;
                switch (x.tactic) {
                  case 'PREROLL':
                    key = 'Video';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'OTT':
                    key = 'CTV';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'BROADSTREET':
                    key = 'Broadstreet - O&O';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'DISPLAY':
                    key = 'Display';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'SOCIAL':
                    key = 'Facebook Ads';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'VIDEO':
                    key = 'Digital Video';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'LINEAR':
                    key = 'Sinclair RSN';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'GAMVIDEO':
                    key = 'Video - O&O';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  case 'GAMDISPLAY':
                    key = 'Display - O&O';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                  default:
                    // eslint-disable-next-line no-console
                    console.log('unhandled polygon data type', p, x);
                    key = 'Impressions';
                    val = utils.formatNumberWithCommas(x.Impressions);
                    break;
                }
                this.tooltipContent += `<div class="tooltipContentLine"><span class="grey--text font-weight-medium">${key}: <span class="primary--text font-weight-bold ma-0">${val}</span></span></div>`;
              });
              this.tooltipContent += '</div>';
            }
            p.tooltipTimeout = setTimeout(() => {
              if (!hovered) {
                return;
              }
              const content = this.$refs.rsnToolTip || this.$refs.mapToolTip;
              if (!content) {
                layerPopup = null;
                return;
              }
              layerPopup = map.CreatePopup(content, latlon, {
                closeButton: false,
                offset: [2, 4],
              });
              const popupEl = layerPopup._wrapper;
              popupEl.style.pointerEvents = 'none';
              if (persistentTooltip) {
                popupEl.style.pointerEvents = 'all';
                // console.log({ popupEl, layerPopup });
                popupEl.addEventListener(
                  'mousemove',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = true;
                  },
                  true,
                );
                popupEl.addEventListener(
                  'mouseover',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = true;
                  },
                  true,
                );
                popupEl.addEventListener(
                  'mouseout',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = false;
                    setTimeout(() => {
                      if (!hovered) {
                        map.ClosePopup(layerPopup);
                        layerPopup = null;
                      }
                    }, 250);
                  },
                  true,
                );
              }
            }, 20);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mousemove', (e: any) => {
            if (!persistentTooltip && !p.useDot && layerPopup) {
              layerPopup.setLatLng(e.latlng);
            }
          })
          .on('click', () => {
            // // eslint-disable-next-line no-console
            // console.log('polygon', p);
          })
          .on('mouseout', () => {
            if (!layer) {
              return;
            }
            hovered = false;
            setTimeout(() => {
              if (hovered) {
                return;
              }
              clearTimeout(p.tooltipTimeout);
              if (layer.setStyle) {
                layer.setStyle(style);
              }
              this.legend = null;
              if (!persistentTooltip && layerPopup) {
                map.ClosePopup(layerPopup);
                layerPopup = null;
              }
            }, 50);
          });
        hasData = true;
      });

      map.SetRenderCacheKey(this.mapRenderCacheKey);
      if ((map.initialized && clear) || !hasData) {
        // don't reposition the map if we're updating/clearing the current map
      } else {
        const paddingOptions = { paddingTopLeft: [this.hasOverlay ? 400 : 20, 20], paddingBottomRight: [20, 20] };
        if (map.initialized) {
          setTimeout(() => {
            if (this.setXportingState()) {
              return;
            }
            if (topScoringLayers.length > 1) {
              map.FlyToLayers(topScoringLayers, { force: true, animate: false, ...paddingOptions });
            } else {
              map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
            }
          }, 10);
        } else {
          setTimeout(() => {
            if (this.setXportingState()) {
              return;
            }
            if (topScoringLayers.length > 1) {
              map.FlyToLayers(topScoringLayers, { force: true, animate: false, ...paddingOptions });
            } else {
              map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
            }
          }, 10);
        }
      }
      this.emitRendered(beforePolygons, map);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    teamHovered(geo: any): void {
      const homeLayer = this.dmaMarketsHash[geo.key];
      const outermarketLayer =
        this.dmaMarketsHash[geo.key.replace('DMA_Region_Optimum_Sports', 'Outermarket_Optimum_Sports')];
      if (homeLayer) {
        homeLayer.setStyle(this.Theme?.polygons?.light.rsnHover);
      }
      if (outermarketLayer) {
        outermarketLayer.setStyle(this.Theme?.polygons?.light.outerMarketHover);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    teamHoveredOut(geo: any): void {
      const homeLayer = this.dmaMarketsHash[geo.key];
      const outermarketLayer =
        this.dmaMarketsHash[geo.key.replace('DMA_Region_Optimum_Sports', 'Outermarket_Optimum_Sports')];
      if (homeLayer) {
        homeLayer.setStyle(this.Theme?.polygons?.light?.rsn);
      }
      if (outermarketLayer) {
        outermarketLayer.setStyle(this.Theme?.polygons?.light?.outerMarket);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitRendered(beforePolygons: boolean, map: any, retries = 5) {
      if (this.fullyRendered === 1) {
        if (this.isExporting) {
          setTimeout(
            () => {
              const mapEl = this.$refs[this.mapCacheKey]?.$el;
              if (!mapEl) {
                // eslint-disable-next-line no-console
                console.error('can not export, no map element');
                if (retries > 0) {
                  setTimeout(() => {
                    this.emitRendered(beforePolygons, map, retries - 1);
                  }, 500);
                } else {
                  setTimeout(() => {
                    this.$emit('rendered', { empty: true });
                  }, 10);
                }
                return;
              }
              const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
              if (!tileBackgroundList || tileBackgroundList.length === 0) {
                // eslint-disable-next-line no-console
                console.error('SummaryMap, can not export, no map background');
                if (retries > 0) {
                  setTimeout(() => {
                    this.emitRendered(beforePolygons, map, retries - 1);
                  }, 500);
                } else {
                  setTimeout(() => {
                    this.$emit('rendered', { empty: true });
                  }, 10);
                }
                return;
              }
              const tileBackground = tileBackgroundList[0] as HTMLElement;

              const zoom = map.GetZoom() - 1; // why do we need the -1 ???
              const center = map.GetCenter();
              const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
              var img = new Image();
              img.addEventListener(
                'load',
                () => {
                  this.exportBackgroundImage = staticMapByCenter;
                  tileBackground.style.display = 'none';
                  setTimeout(() => {
                    this.$emit('rendered', { empty: false });
                  }, 1000);
                },
                false,
              );
              img.src = staticMapByCenter;
              this.fullyRendered = 2;
            },
            beforePolygons ? 1000 : 1000,
          );
        } else {
          setTimeout(() => {
            this.$emit('rendered', { empty: false });
          }, 1000);
        }
      }
    },
    onMapReady(evt): void {
      const { id, redrawing } = evt;
      const map = this.$refs[id]?.Get();
      this.mapId = id;
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }
      if (!redrawing) {
        map.ZoomControlsOnRight();
      }

      this.mapReady = true;
      this.$store.dispatch('summary/setIsMapReady', true);

      if (this.mapRenderCacheKey !== map.renderCacheKey) {
        setTimeout(() => {
          map.Redraw();
        }, 20);
        setTimeout(() => {
          this.renderPolygons({ clear: true });
        }, 500);
      }
      const mapDiv = document.getElementById('summaryMap');
      const resizeObserver = new ResizeObserver(() => {
        map.Resize();
      });

      resizeObserver.observe(mapDiv);
    },
  },
  computed: {
    isPrinting(): boolean {
      return this.$route.query.print === 'true';
    },
    mapHeight(): number {
      if (this.isMobile) return 200;
      return this.$store.state?.summary?.dimensions?.mapHeight || 0;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadingContainerStyles(): any {
      const styles = { height: `${this.mapHeight}px`, 'margin-top': `-${this.mapHeight}px` };
      if (this.$refs.mapContainer?.clientWidth) {
        styles['width'] = `${this.$refs.mapContainer.clientWidth}px`;
      }

      return styles;
    },
    mapRenderCacheKey(): string {
      return `_summaryMap_${this.config.dataCacheKey}_light`;
    },
    mapCacheKey(): string {
      return `_summaryMap_${this.config.dataCacheKey}`;
    },
    mapTilerMapId(): string {
      if (Array.isArray(this.componentConfig?.themes) && this.componentConfig.themes.length) {
        // if only one theme exist
        return utils.getMapId(this.componentConfig.themes[0]);
      }
      return 'hybrid';
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    summaryData(): object {
      return this.isExporting ? this.exportData?.summaryData : this.$store.state.customer?.summaryPerformance;
    },
    disabledProducts(): Array<String> {
      return this.componentConfig?.disabledProducts;
    },
  },
  watch: {
    // watching the map height, we appear to getting a delay setting the summary state object.
    mapHeight: {
      handler(): void {
        if (this.mapReady && this.mapId) {
          const map = this.$refs[this.mapId]?.Get();
          if (!map || !map.host || !map.leaflet) {
            // eslint-disable-next-line no-console
            console.error('map component did not load correctly', map);
            return;
          }
          setTimeout(() => {
            map.Resize();
          }, 20);
        }
      },
    },
    disabledProducts: {
      handler(): void {
        // reset map data if change in custimization
        this.start();
      },
    },
  },
});
