
import Vue from 'vue';
// we need to wait for the new export method to replace the old tactic-table.vue component, for now we create a new one.
import tacticTable from '../../../components/summary/components/tacticTables/tactic-table-new.vue';
import util from '../../../../util';
import debounce from 'lodash.debounce';
import { C360Icon } from '@c360/ui';
import analytics from '../../../../mixins/analytics';
import { validProducts } from '../utils';
import draggable from 'vuedraggable';
import _clone from 'lodash.clonedeep';
import utils from "@/util";

export default Vue.extend({
  name: 'CampaignList',
  data: (): {
    isLoading: boolean;
    rawData: object;
    data: object[];
    itemsPerPageOptions: number[];
    itemsPerPage: number;
    fetchedItems: string[];
    search: string;
    dragOptions: object;
    productsList: string[];
    totalNumberOfCampaigns: number;
    firstLoad: boolean;
    daterange: string;
  } => ({
    isLoading: true,
    data: null,
    rawData: null,
    itemsPerPageOptions: [5, 10, 15],
    itemsPerPage: 5,
    fetchedItems: [],
    search: '',
    dragOptions: {
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
      chosenClass: 'chosen',
      dragClass: 'drag',
    },
    productsList: [],
    totalNumberOfCampaigns: 0,
    firstLoad: true,
    daterange: 'alltime',
  }),
  props: ['componentConfig', 'isExporting', 'isShared'],
  components: { tacticTable, C360Icon, draggable },
  mixins: [analytics],
  async created() {
    this.setXportingState();
    this.firstLoad = true;
    this.daterange = this.$route.query?.summarydaterange || 'alltime';
    this.productList = await this.fetchProducts();
    this.getData();
  },
  computed: {
    isPrinting(): boolean {
      return this.$route.query.print === 'true';
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    disabledProducts(): Array<String> {
      return this.componentConfig?.disabledProducts;
    },
  },
  methods: {
    setXportingState(): boolean {
      const exportState = this.$store.state.customer?.dashxExportState?.[this.componentConfig?.cid];
      if (exportState) {
        console.log(`Apply export state for ${this.componentConfig?.cid}`, exportState);
      }
      // Order of applying params is crucial, since some of them depend on others
      // 1. itemsPerPage
      const exportItemsPerPage = exportState?.itemsPerPage;
      if (exportItemsPerPage) {
        this.itemsPerPage = exportItemsPerPage;
      }
      // 2. search
      const exportSearch = exportState?.search;
      if (exportSearch) {
        this.search = exportSearch;
      }
    },
    trackMovingComponent(): void {
      const newTableOrder = this.data.map(table => table.type);
      const customizations = _clone(this.$store.state.layoutEditor.currCustomizations);
      const currCustimaztions = customizations.find(cust => cust.id === 'summaryController');

      // add new overrides or to pre-existing overrides
      let newOverrides = { tacticTableOrder: newTableOrder };
      const { overrides } =
        this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components[0].components[0];

      if (currCustimaztions?.overrides) {
        newOverrides = { ..._clone(overrides), tacticTableOrder: newTableOrder };
      }

      // update customizations
      currCustimaztions.overrides = newOverrides;

      this.$store.dispatch('layoutEditor/setCurrCustomizations', util.removeNullFromObjectOrArray(customizations));
      this.$store.dispatch('setOverrides', { compId: 'summaryController', overrides: newOverrides });
      this.$store.dispatch('layoutEditor/setEnableSave', true);
    },
    getDateParams(daterange) {
      const dateParams = {};
      switch (daterange) {
        case 'alltime':
          dateParams.daterange = 'alltime';
          break;
        case 'customRange':
          dateParams.daterange = 'customRange';
          dateParams.startdate = this.$route.query?.summarystartdate;
          dateParams.enddate = this.$route.query?.summaryenddate;
          break;
        default:
          dateParams.daterange = daterange;
          break;
      }
      return dateParams;
    },
    async fetchProducts() {
      this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, url: 'getProductsForListOnSummary' });
      const productsResponse = await this.$store.dispatch('getProductsForListOnSummary', {
        advertiserId: this.$route.query?.id || this.$store.state.customer?.selection?.advertiserId,
        ...this.getDateParams(this.daterange),
        isShared: this.isShared,
        view: 'summary',
      });
      const productsList = productsResponse?.CampTypes;
      this.$store.dispatch('setFetching', {
        cid: this.componentConfig.cid,
        url: 'getProductsForListOnSummary',
        completed: true,
      });
      return productsList;
    },
    async fetchCampaigns(productList, productParams) {
      this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, url: 'getSummaryCampaignList' });
      const campaignsData = await this.$store.dispatch('getSummaryCampaignList', {
        advertiserId: this.$route.query?.id || this.$store.state.customer?.selection?.advertiserId,
        tactic: productParams?.tacticsArray || productList,
        limit: this.itemsPerPage,
        offset: productParams?.offset || 0,
        search: this.search,
        ...this.getDateParams(this.daterange),
        isShared: this.isShared,
      });
      this.$store.dispatch('setFetching', {
        cid: this.componentConfig.cid,
        url: 'getSummaryCampaignList',
        completed: true,
      });
      return campaignsData;
    },
    async getData() {
      this.isLoading = true;
      // get products with daterange
      // get data (for each product or for all? check speed) with dateranges
      // compare with order and list from configurability, exclude the hidden ones and render in needed order
      // const productList = await this.fetchProducts('alltime');
      const tables = await this.fetchCampaigns(this.productList);
      this.rawData = tables;

      let productsList = validProducts;
      const customizations = this.$store.state.layoutEditor.currCustomizations[0];
      if (customizations?.overrides?.tacticTableOrder) {
        productsList = customizations.overrides.tacticTableOrder;
      }
      if (customizations?.overrides?.disabledProducts) {
        productsList = productsList.filter(product => !customizations.overrides.disabledProducts.includes(product));
      }

      productsList = productsList.filter(product => tables[product]);

      productsList = productsList.map(product => {
        let item = tables[product];
        item = {
          ...item,
          TagName: item.TagName.toUpperCase(),
          currentPage: item.currentPage || 1,
          limit: this.itemsPerPage,
          offset: item.offset || 0,
          tacticName: util.tacticTitleMap(item.TagName),
          totalObjects: item.totalObjects,
          totalPages: item.totalPages,
          type: item.TagName === 'Sinclair RSN' ? 'LINEAR' : item.TagName.toUpperCase(),
          campaigns: item.CampaignList,
        };
        return item;
      });
      if (this.firstLoad) {
        this.firstLoad = false;
        this.totalNumberOfCampaigns = tables?.NumberOfCampaigns;
      }
      this.data = productsList;
      this.isLoading = false;
    },
    getDataDebounced: debounce(function (): void {
      this.getData();
    }, 2000),
    async fetchDataItem(itemParam, isPagination) {
      try {
        if (!isPagination) this.isLoading = true;
        if (itemParam?.tacticsArray.length) {
          this.fetchedItems = [...this.fetchedItems, ...itemParam?.tacticsArray];
        }
        const params = {
          offset: itemParam?.offset,
          tactic: itemParam?.tacticsArray,
        };
        const campaignsData = await this.fetchCampaigns(itemParam.tacticsArray, params);

        let fetchedTactic;
        if (itemParam?.tacticsArray.length === 1) {
          fetchedTactic = itemParam.tacticsArray[0];
        } else {
          fetchedTactic = itemParam.tacticsArray.includes('DISPLAY') ? 'DISPLAY' : 'PREROLL';
        }
        if (!campaignsData[fetchedTactic]?.CampaignList?.length) {
          if (itemParam?.tacticsArray)
            this.fetchedItems = this.fetchedItems.filter(item => !itemParam?.tacticsArray.includes(item));
          this.data = [];
          return;
        }

        const campaignsToDisplay = campaignsData[fetchedTactic];
        if (!isPagination) {
          this.data = campaignsToDisplay;
        } else {
          for (const [key, value] of Object.entries(this.data)) {
            if (value.type === fetchedTactic) {
              value.campaigns = campaignsToDisplay?.CampaignList;
              value.offset = campaignsToDisplay?.offset;
              value.currentPage = campaignsToDisplay?.currentPage;
            }
          }
          if (itemParam?.tacticsArray)
            this.fetchedItems = this.fetchedItems.filter(item => !itemParam?.tacticsArray.includes(item));
        }
      } catch (error) {
        console.error(`Error fetching data for ${name}:`, error);
      } finally {
        this.isLoading = false;
      }
    },
    async paginateCampaigns(tactic, offset) {
      let tacticsArray;
      switch (tactic) {
        case 'DISPLAY':
          tacticsArray = ['DISPLAY', 'SIMPGEOFENCE', 'GTDISPLAY'];
          break;
        case 'PREROLL':
          tacticsArray = ['PREROLL', 'GTVIDEO'];
          break;
        default:
          tacticsArray = [tactic];
          break;
      }

      const params = {
        tacticsArray,
        offset,
        limit: this.itemsPerPage,
        search: this.search,
      };
      await this.fetchDataItem(params, true);
    },
    setCurrentSection(section: any): void {
      this.$emit('set-current-section', section);
    },
    setItemsPerPage(option) {
      this.analyticTrack(
        this.trackValue.ORDERS_CHANGE_NUMBER_OF_ITEMS,
        `New value: ${option}, Order ID: ${this.$route.query?.orderId}`,
      );
      this.itemsPerPage = option;
      utils.updateExportState(this.componentConfig.cid, 'itemsPerPage', this.itemsPerPage);
    },
    tableHeaders(tactic: string): any {
      const rules = {
        Tactics: ['Display', 'Digital Video (Video + OTT)', 'Video', 'CTV'],
        Impressions: [
          'Display',
          'Digital Video (Video + OTT)',
          'Video',
          'CTV',
          'Facebook Ads',
          'SEM',
          // 'Digital Video',
          'YouTube',
          'GAM',
          'Sinclair RSN',
          'Video - O&O',
          'Display - O&O',
          'Broadstreet - O&O',
          'Audio',
          'InnovidXP',
          'True Geo',
        ],
        ImpressionGoal: ['Display', 'Digital Video (Video + OTT)', 'Video', 'CTV', 'Audio', 'True Geo'],
        ImpressionGoalPercent: [
          'Display',
          'Digital Video (Video + OTT)',
          'Video',
          'CTV',
          'Display - O&O',
          'Audio',
          'True Geo',
        ],
        Views: ['Email Marketing'],
        Clicks: ['Email Marketing', 'Broadstreet - O&O'],
        Hovers: ['Broadstreet - O&O'],
        ClickToViewRate: ['Email Marketing'],
        ClickThrough: ['SEM'],
        TotalProcessedCalls: ['Call Tracking'],
        DurationAvg_ProcessedCalls: ['Call Tracking'],
        Aired: ['TV'],
        Visits: ['TV'],
        VisitsAiring: ['TV'],
        all: ['Pacing', 'FriendlyName', 'StartDate', 'EndDate', 'Conversions'],
      };

      const orderByValue = [
        'Pacing',
        'FriendlyName',
        'Tactics',
        'StartDate',
        'EndDate',
        'Conversions',
        'Impressions',
        'ImpressionGoal',
        'ImpressionGoalPercent',
        'Views',
        'Clicks',
        'ClickToViewRate',
        'ClickThrough',
        'TotalProcessedCalls',
        'DurationAvg_ProcessedCalls',
        'Hovers',
        'Aired',
        'Visits',
        'VisitsAiring',
      ];

      const headers = orderByValue.reduce((all: Header[], value: string) => {
        const header = { align: 'left', text: 'Campaign name', value };
        if (value === 'FriendlyName') {
          header['sortable'] = false;
          header['width'] = '40%';
        } else {
          if (value === 'Impressions') {
            if (tactic.toLowerCase() === 'sinclair rsn') header['text'] = 'Household Imps';
            else header['text'] = 'Imps All Time';
          } else if (value === 'Pacing') {
            header['width'] = '90px';
            header['text'] = util.headerNamesMap(value);
          } else if (value === 'Tactics') {
            header['text'] = 'Products';
          } else if (value === 'Conversions') {
            header['text'] = 'Convs All Time';
          } else {
            header['text'] = util.headerNamesMap(value);
          }
        }

        if ((rules[value] && rules[value].includes(tactic)) || rules.all.includes(value)) {
          all.push(header);
        }
        return all;
      }, []);

      headers.forEach(header => {
        const minNumberOfSymbolsInLargePacing = 9;
        const data = this.data.find(obj => obj.tacticName === tactic);
        const hasLargePacing = data?.campaigns?.some(
          campaign => campaign?.Pacing?.length > minNumberOfSymbolsInLargePacing,
        );

        if (header['value'] === 'FriendlyName') {
          header['width'] = hasLargePacing ? '38%' : '40%';
          return;
        }
        if (header['value'] === 'Pacing') {
          if (hasLargePacing) {
            header['width'] = '130px';
          } else {
            header['width'] = '90px';
          }
          return;
        }
      });
      return headers;
    },
    updateAvailableTables(): void {
      // console.log('updateAvailableTables');
      // this.data = this.data.filter(table => !this.disabledProducts.includes(table.type));
      let productsList = validProducts;
      const customizations = this.$store.state.layoutEditor.currCustomizations[0];
      if (customizations?.overrides?.tacticTableOrder) {
        productsList = customizations.overrides.tacticTableOrder;
      }
      if (customizations?.overrides?.disabledProducts) {
        productsList = productsList.filter(product => !customizations.overrides.disabledProducts.includes(product));
      }

      productsList = productsList.filter(product => this.rawData[product]);

      productsList = productsList.map(product => {
        let item = this.rawData[product];
        item = {
          ...item,
          currentPage: item.currentPage || 1,
          limit: this.itemsPerPage,
          offset: item.offset || 0,
          tacticName: util.tacticTitleMap(item.TagName),
          totalObjects: item.totalObjects,
          totalPages: item.totalPages,
          type: item.TagName === 'Sinclair RSN' ? 'LINEAR' : item.TagName.toUpperCase(),
          campaigns: item.CampaignList,
        };
        return item;
      });
      this.data = productsList;
    },
  },
  watch: {
    disabledProducts: {
      handler(): void {
        // reset table data if change in custimization
        this.updateAvailableTables();
      },
    },
    itemsPerPage: {
      handler(): void {
        // reset to page one when items per page changes
        this.getData();
      },
    },
    search: {
      handler(): void {
        utils.updateExportState(this.componentConfig.cid, 'search', this.search);
        // reset to page one when search changes
        this.getDataDebounced();
      },
    },
    // data: {
    //   handler(oldVal, newVal): void {
    //     if (!this.inEditMode) return;

    //     const previousTacticsOrder = JSON.stringify(oldVal.map(table => table.type));
    //     const newTacticOrder = JSON.stringify(newVal.map(table => table.type));
    //     if (previousTacticsOrder === newTacticOrder) return;

    //     // to correctly support changing of products removal/adding/order
    //     // length of disabled + ordered products should be equal to the length all products
    //     // if no - push items which are in desabled and are not in ordered to the end of ordered
    //     const newTableOrder = this.data.map(table => table.type);
    //     console.log(newTableOrder?.length, this.disabledProducts?.length, validProducts?.length);
    //     if (newTableOrder?.length + this.disabledProducts?.length !== validProducts?.length) {
    //       console.log('missing!');
    //     }

    //     const customizations = _clone(this.$store.state.layoutEditor.currCustomizations);
    //     const currCustimaztions = customizations.find(cust => cust.id === 'summaryController');

    //     // add new overrides or to pre-existing overrides
    //     let newOverrides = { tacticTableOrder: newTableOrder };
    //     const { overrides } =
    //       this.$store.state.customer.dynamicLayout.layoutCustomizations.config.components[0].components[0];

    //     if (currCustimaztions?.overrides) {
    //       newOverrides = { ..._clone(overrides), tacticTableOrder: newTableOrder };
    //     }

    //     // update customizations
    //     currCustimaztions.overrides = newOverrides;

    //     this.$store.dispatch('layoutEditor/setCurrCustomizations', util.removeNullFromObjectOrArray(customizations));
    //     this.$store.dispatch('setOverrides', { compId: 'summaryController', overrides: newOverrides });
    //     this.$store.dispatch('layoutEditor/setEnableSave', true);
    //   },
    // },
    '$route.query.summarydaterange': {
      handler(newPeriod: string, oldPeriod: string): void {
        if (newPeriod !== oldPeriod) {
          this.daterange = newPeriod;
        }
      },
      immediate: false,
    },
    '$route.query.startdate': {
      handler(newDate: string, oldDate: string): void {
        if (newDate && newDate !== oldDate) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
    '$route.query.enddate': {
      handler(newDate: string, oldDate: string): void {
        if (newDate && newDate !== oldDate) {
          this.readQueryString();
        }
      },
      immediate: false,
    },
  },
});
