
import Vue from 'vue';
import gradstop from 'gradstop';
import MapComponent from '@point/map-component';
import mapCampaignSummaryController from './mapCampaignSummary/mapCampaignSummaryController.vue';
import EditModuleBtn from '../charts/buttons/editModuleBtn.vue';
import NoMapData from '../charts/no-data/NoMapData.vue';
import utils from '../../../util';
import ERRORS from '../../../errors';
// for fallback when Social/Facebook Ads is missing DMA data
import genericMap from './genericMap.vue';
import debounce from 'lodash.debounce';

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'dmaZipMap',
  components: { MapComponent, NoMapData, mapCampaignSummaryController, EditModuleBtn, genericMap },
  props: {
    sectionConfig: Object,
    componentConfig: Object,
    title: String,
    theme: Object,
    isExporting: Boolean,
    isExportDynamic: Boolean,
    exportData: Object,
    exportContext: Object,
    componentHeight: Number,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data(): any {
    return {
      geoPolygons: {},
      geoLookupByID: {},
      zipPolygons: {},
      zipLookupByID: {},
      zipMaxRate: 0,
      geoMaxValue: 0,
      dmaPickerTitle: 'All DMAs',
      dmaPickerList: [],
      geoData: null,
      activeDMA: null,
      loading: true,
      loadedValidData: true,
      hasRequiredData: false,
      hasRendered: false,
      mapReady: false,
      legend: false,
      dataCacheKey: null,
      layoutThemes: [],
      defaultToZips: true,
      renderDots: true,
      hoveredGeo: null,
      dmaMarketsHash: {},
      useGeoList: false,
      hoveredZipText: [],
      fullyRendered: 0,
      exportBackgroundImage: null,
      mapId: null,
      renderMap: true,
      mapRefreshed: false,
    };
  },
  computed: {
    isPrinting(): boolean {
      return this.$route.query.print === 'true';
    },
    mapShowDataFrom(): string {
      return this.componentConfig.overrides?.mapShowDataFrom;
    },
    isGroundTruth(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('GROUNDTRUTH');
    },
    byGeofenceImpressions(): any {
      const geo_locations = utils.adDataForKey(this, `${this.tactic}.GeoPerformanceAddressImpression`) || [];
      const geo_cities = utils.adDataForKey(this, `${this.tactic}.GeoPerformanceImpression`) || [];

      // if there is no geofencing locations, return cities
      if (geo_locations?.length === 1 && geo_locations[0]?.AddressName === 'No geofencing locations specified') {
        return geo_cities.map(city => {
          return {
            ...city,
            AddressId: city?.CityId || city?.geoParamKey,
            AddressName: `${city?.City}, ${city?.Stat}`,
            isCity: true,
          };
        });
      }

      return geo_locations;
    },
    loadGeofencingImpressions(): boolean {
      const showDataFromGeofencingLocations = this.mapShowDataFrom === 'geofencingLocations' || !this.mapShowDataFrom;
      return this.isGroundTruth && this.hasByGeofenceImpressions && this.isGTTactic && showDataFromGeofencingLocations;
    },
    hasByGeofenceImpressions(): boolean {
      return this.byGeofenceImpressions?.length > 0;
    },
    isGTTactic(): boolean {
      return this.tactic === 'GTDISPLAY' || this.tactic === 'GTVIDEO';
    },
    tactic(): string {
      return this.$route.query?.tab?.toUpperCase() || this.$store.state.customer?.selection?.tab?.toUpperCase();
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
    geoListPerformancePayload(): any {
      // If params are not present in the url (like in shared view) find them in the store
      const id = this.$route.query?.id || this.$store.state.customer?.selection?.advertiserId || '';
      const daterange = this.$route.query?.daterange || this.$store.state.customer?.selection?.daterange || 'alltime';
      const startdate =
        this.$route.query?.campaignstartdate ||
        utils.formatDate(this.$store.state.customer?.selection?.startdate) ||
        '';
      const enddate =
        this.$route.query?.campaignenddate || utils.formatDate(this.$store.state.customer?.selection?.enddate) || '';
      const campaignsIds = this.$route.query?.viewCampaigns?.split(',');
      const type = this.$route.query?.tab?.toUpperCase() || this.$store.state.customer?.selection?.tab?.toUpperCase();

      const campaigns = campaignsIds?.map(c => {
        return {
          id: c,
          type: type,
        };
      });

      return {
        daterange,
        advertiserId: id,
        campaigns,
        startdate: startdate,
        enddate: enddate,
        isShared: this.isShared,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mergedCompConfig(): any {
      return { ...this.componentConfig, ...this.fallbackMap };
    },
    isLoading(): boolean {
      if (this.loading) {
        return true;
      }
      if (utils.isWaitingOnData(this)) {
        return true;
      }
      return false;
    },
    mapRenderCacheKey(): string {
      return `_dmaZipMap_${this.dataCacheKey}_light`;
    },
    mapCacheKey(): string {
      return `_dmaZipMap_${this.dataCacheKey}`;
    },
    mapTilerMapId(): string {
      const isPrinting = this.$route.query.print === 'true';
      if (isPrinting) {
        return utils.getMapId('printing');
      }
      const { theme } = this.$store.state.customer;
      if (this.layoutThemes?.length === 1) {
        // if only one theme exist
        return utils.getMapId(this.layoutThemes[0]);
      }
      if (this.layoutThemes?.length > 1) {
        // if dark and light exist
        const returnTheme = theme ? this.layoutThemes[1] : this.layoutThemes[0];
        return utils.getMapId(returnTheme);
      }
      return '24753aa3-7a2d-4bb6-9370-e7d657b08efb';
    },
    selectedDMAs(): string[] {
      const { dmas } = this.$route.query;
      if (!dmas || dmas.length === 0) {
        return [];
      }
      return dmas.split(',');
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    demographicsOnly(): boolean {
      return this.componentConfig.customStyle === 'demographicsOnly';
    },
    replacementDataSource(): string | boolean {
      return this.componentConfig?.fallbackMap?.dataSource;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fallbackMap(): any {
      return this.componentConfig?.fallbackMap;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    onlySummary(): boolean {
      return this.componentConfig.onlySummary;
    },
    onlyMap(): boolean {
      return this.componentConfig.onlyMap;
    },
  },
  mounted() {
    if (!this.isExporting) {
      this.loading = false;
    }
    this.$store.dispatch('setFetching', { cid: this.componentConfig.cid });

    // only initMap when data is loaded, there may be old data from previous mount
    setTimeout(() => {
      if (!utils.isWaitingOnData(this)) {
        this.debouncedInitMap();
      }
    }, 1000);

    unwatchDataChanges = utils.fireOnAdDataChange(this, this.debouncedInitMap, true);
  },
  beforeDestroy(): void {
    unwatchDataChanges();
  },
  watch: {
    mapRenderCacheKey: {
      handler(key: string, old: string): void {
        if (old.indexOf('_null_') > 0) {
          return; // ignore the first mounted, before data is available
        }

        setTimeout(() => {
          this.renderDMAorZips({ clear: false });
        }, 500);
      },
    },
    selectedDMAs: {
      handler(newVal: string[], oldVal: string[]): void {
        if (newVal.length === 0 && oldVal.length === 0) {
          return;
        }
        this.renderDMAorZips({ clear: true });
      },
    },
    '$route.query.id': {
      handler(): void {
        this.loading = true;
      },
      immediate: false,
    },
    '$route.query.viewCampaigns': {
      handler(): void {
        this.debouncedInitMap();
      },
      immediate: false,
    },
    byGeofenceImpressions: {
      handler(newValue, oldValue) {
        const update = newValue !== oldValue;
        if (update && !this.isExporting) {
          this.debouncedInitMap();
        }
      },
      deep: true,
    },
  },
  methods: {
    setXportingState(): boolean {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map) return;
      const exportState = this.$store.state.customer?.dashxExportState?.[this.componentConfig?.cid];
      const currentZoom = exportState?.zoomLevel || map.GetZoom();
      if (exportState?.center) {
        map.FlyTo(exportState?.center, currentZoom, { animate: false });
        return true;
      }
      return false;
    },
    debouncedInitMap: debounce(function (): void {
      this.initMap();
    }, 1000),
    async initMap() {
      if (this.demographicsOnly) {
        this.loading = false;
        return;
      }
      if (this.onlySummary) {
        this.loading = false;
        return;
      }

      // when navigation across tactics, this map control gets recycled, not unmounted/remounted
      this.mapReady = false;
      this.geoData = [];
      this.geoPolygons = {};
      this.geoLookupByID = {};
      this.zipPolygons = {};
      this.zipLookupByID = {};
      this.zipMaxRate = 0;
      this.geoMaxValue = 0;
      this.hoveredGeo = null;
      this.loading = false;
      this.loadedValidData = true;
      this.hasRequiredData = false;
      this.useGeoList = false;
      this.legend = false;
      this.dataCacheKey = null;
      this.defaultToZips = true;
      this.renderDots = true;

      let dataSourceKey = this.componentConfig.dataSource;

      if (Array.isArray(this.componentConfig.dataSource) && this.$store.state.filters.selectedCampaigns) {
        // special case for GAM
        dataSourceKey = this.componentConfig.dataSource.find((source: string) => {
          return source === this.$store.state.filters.selectedCampaigns[0].CampaignType;
        });
      }

      let dataSource = '';
      let dataCheck: any; // eslint-disable-line @typescript-eslint/no-explicit-any

      if (this.isExporting) {
        dataCheck = this.exportData.adData[dataSourceKey]
          ? this.exportData.adData[dataSourceKey]
          : this.exportData.adData[this.replacementDataSource];
      } else {
        dataCheck = utils.adDataForKey(this, dataSourceKey);
      }

      if (!dataCheck && this.isExporting) {
        // console.error('no map data', dataSourceKey);
        setTimeout(() => {
          if (!this.hasRequiredData) {
            // do not tell fileexport the render failed if we are falling back to the genericMap
            if (!this.fallbackMap) {
              if (this.hasRendered) {
                return;
              }
              this.$emit('rendered', { empty: true, check: 'required data #1', dataSourceKey });
              this.hasRendered = true;
            }
          }
        }, 10);
        return;
      }

      let gData;
      // only for current exporting, skip for DashX
      if (this.isExporting && !this.isPrinting) {
        gData = utils.adDataForKey(this, `${dataSourceKey}.GeoList`);
      } else {
        if (this.loadGeofencingImpressions) {
          gData = await this.getGeocodes();
        } else {
          gData = await this.getGeoListPerformance();
        }
      }

      if (gData) {
        dataSource = `${dataSourceKey}.GeoList`;
        this.useGeoList = true;
      } else {
        gData = utils.adDataForKey(this, `${dataSourceKey}.DMAList`);
        dataSource = `${dataSourceKey}.DMAList`;
      }

      if (gData) {
        // PROD-6847: temporary solution to reduce number of polygons for Schneider advertiser map
        const advertisersToReducePolygons = ['8AA3AA378B792586AD325D83F0A17554'];
        const currentAdvertiserID = this.$store.state.customer.selection.advertiserId;

        if (advertisersToReducePolygons.includes(currentAdvertiserID)) {
          const minimalPercent = 0.08;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          gData = gData.reduce((allZips: Array<any>, zipObject: { ImpressionPercent: string }) => {
            // filters out zips with less than the required impression percentage

            // if there is no impressionpercent ignore this check
            if (!zipObject.hasOwnProperty('ImpressionPercent')) allZips.push(zipObject);

            const impressionPercent = parseFloat(zipObject.ImpressionPercent);
            if (impressionPercent > minimalPercent) {
              allZips.push(zipObject);
            }
            return allZips;
          }, []);
        }
      }

      this.geoData = gData;
      const selectionKey =
        `${this.$route.query.view || ''} ${this.$route.query.id || ''} ` +
        `${this.$route.query.tab || ''} ${this.$route.query.viewCampaigns || ''} ` +
        `${this.$route.query.daterange || ''} ${this.$route.query.startdate || ''} ` +
        `${this.$route.query.enddate || ''}`;
      this.dataCacheKey = `${dataSource}_${selectionKey}`.trim().replace(/[\W_]+/g, '_');

      if (utils.isWaitingOnData(this)) {
        return;
      }
      // bug fix for only one unknown geo DASH-1943
      const gDataCheck = this.dataCacheKey && this.geoData && Array.isArray(this.geoData) && this.geoData.length > 0;
      let unknownOnly = false;
      if (gDataCheck) {
        unknownOnly = this.geoData.length === 1 && this.geoData[0]?.Type?.toLowerCase() === 'unknown';
      }

      this.hasRequiredData = gDataCheck && !unknownOnly;

      if (!this.hasRequiredData) {
        this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
        this.loading = false;
        setTimeout(() => {
          if (this.demographicsOnly) {
            // handled by map-campaign-summary-controller
          } else if (!this.onlySummary && this.hasRequiredData && this.loadedValidData) {
            // handled by map
          } else if (this.fallbackMap && this.replacementDataSource) {
            // handled by map fallback
          } else if (!this.onlyMap) {
            // handled by map-campaign-summary-controller
          } else {
            if (this.hasRendered) {
              return;
            }
            this.$emit('rendered', { empty: true, check: 'required data #2' });
            this.hasRendered = true;
          }
        }, 10);
        return;
      }

      if (this.useGeoList) {
        const RSNMergedGeoData = [];
        const RSNMergedById = {};
        const RSNOuterMarkets = {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.geoData.forEach((geo: any) => {
          if (geo.Type !== 'OuterMarket') {
            return;
          }
          const marketKey = `${geo.name} | ${geo.League} | ${geo.Team}`;
          if (RSNOuterMarkets[marketKey]) {
            // eslint-disable-next-line no-console
            console.log('duplicate outer market', geo);
          }
          RSNOuterMarkets[marketKey] = geo;
        });

        // find outer markets first
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.geoData.forEach((geo: any) => {
          if (geo.Type === 'OuterMarket') {
            return;
          }
          // merge DMA polygons data for RSN entries (multiple teams per `DMA)
          if (geo.Type === 'DMA Region' && geo.GeoKey?.includes('_RSN_')) {
            if (!RSNMergedById[geo.GeoParamVal]) {
              RSNMergedById[geo.GeoParamVal] = {
                Impressions: -1,
                GeoKey: '',
                name: '',
                Type: '',
                Lat: geo.Lat,
                Long: geo.Long,
                GeoParamVal: geo.GeoParamVal,
                GeoParamKey: geo.GeoParamKey,
                legacy: [],
                teams: [],
                table: { headers: [], data: [] },
                outerMarket: null,
              };
            }
            const merged = RSNMergedById[geo.GeoParamVal];
            if (geo.League || geo.Team || geo.RSN) {
              const marketKey = `${geo.name} | ${geo.League} | ${geo.Team}`;
              const outerMarket = RSNOuterMarkets[marketKey];
              if (outerMarket) {
                merged.outerMarket = outerMarket;
              }
              merged.teams.push(geo);
            } else {
              merged.legacy.push(geo);
            }
            if (geo.Impressions > merged.Impressions) {
              RSNMergedById[geo.GeoParamVal] = { ...merged, ...geo };
            }
          } else {
            RSNMergedGeoData.push(geo);
          }
        });
        Object.keys(RSNMergedById).forEach((key: string) => {
          RSNMergedGeoData.push(RSNMergedById[key]);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        RSNMergedGeoData.forEach((geo: any) => {
          // ignore 'other' zip codes | todo, find other types of geos
          if (
            (geo.GeoKey && utils.isUnknownOrOtherOrInvalidString(geo.GeoKey)) ||
            (geo.Zipcode && utils.isUnknownOrOtherOrInvalidString(geo.Zipcode)) ||
            (geo.Metro && utils.isUnknownOrOtherOrInvalidString(geo.Metro)) ||
            (geo.name && utils.isUnknownOrOtherOrInvalidString(geo.name))
          ) {
            return;
          }
          // only look for the max score in 'valid' polygons
          if (geo.name !== '(Not Set)' && geo.GeoParamVal !== '0') {
            if (geo.Impressions && geo.Impressions > this.geoMaxValue) {
              this.geoMaxValue = geo.Impressions;
            } else if (geo.PageViews && geo.PageViews > this.geoMaxValue) {
              this.geoMaxValue = geo.PageViews;
            }
          }
          // this.geoLookupByID[`${geo.GeoParamKey}:${geo.GeoParamVal}`] = { ...geo };
          const dup = this.geoLookupByID[geo.GeoParamVal] || {};
          this.geoLookupByID[geo.GeoParamVal] = { ...dup, ...geo };
        });
        this.loadAllGeos();
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.geoData.forEach((dma: any) => {
          // ignore 'other' DMAs
          if (dma.DMA && utils.isUnknownOrOtherOrInvalidString(dma.DMA)) {
            return;
          }
          let score = parseFloat(dma.Rate);
          if (Number.isNaN(score) || score < 0) {
            score = 0;
          } else if (score > 100) {
            score = 100;
          }
          if (score > this.geoMaxValue) {
            this.geoMaxValue = score;
          }
          this.geoLookupByID[`${dma.DMA}`] = { ...dma, score };
        });
        this.loadAllZips();
      }

      this.legend = this.componentConfig.legend;
      this.layoutThemes = this.componentConfig?.themes;
    },
    refreshMap(): void {
      if (this.mapRefreshed) return;
      this.renderMap = false;

      setTimeout(() => {
        this.renderMap = true;

        const map = this.$refs[this.mapCacheKey]?.Get();
        if (map) {
          if (typeof currentZoom === 'number') {
            map.SetZoom(currentZoom - 1, { animate: false });
            this.drawBackground();

            setTimeout(() => {
              map.SetZoom(currentZoom, { animate: true });
              this.mapRefreshed = true;
            }, 1000);
          }
        }
      }, 500);
    },
    debounceRefreshMap: debounce(function (): void {
      this.refreshMap();
    }, 2000),
    async getGeoListPerformance(): Promise<void> {
      try {
        const { data } = await this.$store.dispatch(
          'performance/getGeoListPerformance',
          this.geoListPerformancePayload,
        );

        if (data) {
          return data[this.componentConfig.dataSource];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getGeocodes(): Promise<any[]> {
      try {
        const max_locations_allowed = 50;
        const locations = this.byGeofenceImpressions
          ?.sort((a, b) => b.Impressions - a.Impressions)
          ?.map(item => item?.AddressName)
          ?.splice(0, max_locations_allowed);

        const geofenceHasLatLong = this.byGeofenceImpressions.some(item => item.Lat && item.Long);

        // If data in byGeofenceImpressions has Lat and Long, use it directly
        if (geofenceHasLatLong) {
          let items = this.byGeofenceImpressions.filter(item => item.Lat && item.Long);
          items = items.map((item: any) => ({
            ...item,
            Lat: item.Lat,
            Long: item.Long,
            isGeofencingLocation: true,
            GeoParamVal: item.AddressId,
          }));
          return this.assignScoresToGeofencingData(items);
        }

        // If not, get geocodes from API
        const geocodes = await this.$store.dispatch('getGeocodes', { locations: locations });
        let mapAdData = [];
        if (geocodes?.length) {
          geocodes.forEach(geocode => {
            const latitude = geocode.data?.[0]?.latitude;
            const longitude = geocode.data?.[0]?.longitude;
            const data = this.byGeofenceImpressions.find(item => item.AddressName === geocode.location);
            if (latitude && longitude && data) {
              mapAdData.push({
                ...data,
                Lat: latitude,
                Long: longitude,
                isGeofencingLocation: true,
                GeoParamVal: data.AddressId,
              });
            }
          });
          return this.assignScoresToGeofencingData(mapAdData);
        }
        return [];
      } catch (error) {
        console.error('getGeocodes fetch error', error);
        // fallback to geoListPerformance if geocodes reach max limit of requests
        return this.getGeoListPerformance();
      }
    },
    assignScoresToGeofencingData(geoList: any[]): any[] {
      if (!geoList || geoList.length === 0) return [];

      const impressions = geoList.map(item => item.Impressions);
      const minImpressions = Math.min(...impressions);
      const maxImpressions = Math.max(...impressions);

      if (minImpressions === maxImpressions) {
        // If all impressions are the same, assign a score of 3 to all
        return geoList.map(item => ({ ...item, score: 3 }));
      } else {
        const range = maxImpressions - minImpressions;
        return geoList.map(item => {
          // Calculate the proportional score
          const normalizedScore = (item.Impressions - minImpressions) / range;
          // Convert to a scale of 1 to 6 and round
          const score = Math.round(normalizedScore * 5) + 1;
          return { ...item, score };
        });
      }
    },
    getIconClassName(score: number) {
      if (score === 2) return 'two';
      if (score === 3) return 'three';
      if (score === 4) return 'four';
      if (score === 5) return 'five';
      if (score === 6) return 'six';
      return '';
    },
    hijackRendered(): void {
      setTimeout(() => {
        if (this.hasRendered) {
          return;
        }
        this.$emit('rendered', { empty: false });
        this.hasRendered = true;
      }, 10);
    },
    drawBackground() {
      const mapEl = this.$refs[this.mapId]?.$el;
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map) {
        // eslint-disable-next-line no-console
        console.error('DMAZipMap, can not export, no map', retries);
        return;
      }
      const zoom = Math.round(map.GetZoom()) - 1;
      const center = map.GetCenter();
      // const bounds = map.GetBounds();
      // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl?.offsetWidth}x${mapEl?.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      var img = new Image();
      img.addEventListener(
        'load',
        () => {
          this.exportBackgroundImage = staticMapByCenter;
          // tileBackground.style.display = 'none';
          setTimeout(() => {
            this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
            if (this.hasRendered) {
              return;
            }
            this.$emit('rendered', { empty: false });
            this.hasRendered = true;
          }, 1000);
        },
        false,
      );
      img.src = staticMapByCenter;
      this.fullyRendered = 2;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitRendered(doneRendering: boolean, map: any, mapId: string, retries = 5): void {
      if (this.demographicsOnly || this.hasRendered) {
        return;
      }
      // console.log('doneRendering', doneRendering, retries);
      if (doneRendering && this.fullyRendered === 0) {
        this.fullyRendered = 1;
      }
      if (this.fullyRendered === 1) {
        if (this.isExporting) {
          setTimeout(() => {
            const mapEl = this.$refs[mapId]?.$el;
            if (!mapEl) {
              // eslint-disable-next-line no-console
              console.error('DMAZipMap, can not export, no map element', retries);
              if (retries > 0) {
                setTimeout(() => {
                  this.emitRendered(doneRendering, map, mapId, retries - 1);
                }, 500);
              } else {
                setTimeout(() => {
                  if (this.hasRendered) {
                    return;
                  }
                  this.$emit('rendered', { empty: true, check: 'map element' });
                  this.hasRendered = true;
                }, 10);
              }
              return;
            }
            const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
            if (!tileBackgroundList || tileBackgroundList.length === 0) {
              // eslint-disable-next-line no-console
              console.log('DMAZipMap, waiting for map background...', retries);
              if (retries > 0) {
                setTimeout(() => {
                  this.emitRendered(doneRendering, map, mapId, retries - 1);
                }, 500);
              } else {
                // eslint-disable-next-line no-console
                console.error('DMAZipMap, can not export, no map background', retries);
                setTimeout(() => {
                  if (this.hasRendered) {
                    return;
                  }
                  this.$emit('rendered', { empty: true, check: 'map background' });
                  this.hasRendered = true;
                }, 10);
              }
              return;
            }
            const tileBackground = tileBackgroundList[0] as HTMLElement;
            if (!map) {
              map = this.$refs[this.mapCacheKey]?.Get();
            }
            if (!map) {
              // eslint-disable-next-line no-console
              console.error('DMAZipMap, can not export, no map', retries);
              return;
            }
            const zoom = Math.round(map.GetZoom()) - 1;
            const center = map.GetCenter();
            // const bounds = map.GetBounds();
            // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
            const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl?.offsetWidth}x${mapEl?.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
            var img = new Image();
            img.addEventListener(
              'load',
              () => {
                this.exportBackgroundImage = staticMapByCenter;
                tileBackground.style.display = 'none';
                setTimeout(() => {
                  this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
                  if (this.hasRendered) {
                    return;
                  }
                  this.$emit('rendered', { empty: false });
                  this.hasRendered = true;
                }, 1000);
              },
              false,
            );
            img.src = staticMapByCenter;
            this.fullyRendered = 2;
          }, 500);
        } else {
          setTimeout(() => {
            if (this.hasRendered) {
              return;
            }
            this.$emit('rendered', { empty: false });
            this.hasRendered = true;
          }, 100);
        }
      }
    },
    onMapReady(evt): void {
      if (this.demographicsOnly) {
        return;
      }
      const { id, redrawing } = evt;
      const map = this.$refs[id]?.Get();
      this.mapId = id;
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }
      if (!redrawing) {
        map.ZoomControlsOnRight();
      }
      this.mapReady = true;
      if (this.mapRenderCacheKey !== map.renderCacheKey) {
        setTimeout(() => {
          map.Redraw();
        }, 20);
        setTimeout(() => {
          this.renderDMAorZips();
        }, 500);
      }
      const mapDiv = document.getElementById('dmaZipMap');
      const resizeObserver = new ResizeObserver(() => {
        map.Resize();
      });

      resizeObserver.observe(mapDiv);
    },
    renderDMAorZips(options): void {
      if (this.useGeoList) {
        this.renderAllGeos(options);
      } else if (this.defaultToZips) {
        // console.log('renderAllZips from renderDMAorZips', options);
        this.renderAllZips(options);
      } else {
        this.renderDMAs(options);
      }
    },
    saveExportState(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map) return;
      const zoomLevel = map.GetZoom();
      const center = map.GetCenter();
      utils.updateExportState(this.componentConfig.cid, 'zoomLevel', zoomLevel);
      utils.updateExportState(this.componentConfig.cid, 'center', center);
    },
    debounceMapMoved(): void {
      clearTimeout(this.onMapMovedTimer);
      this.drawBackground();
      this.saveExportState();
      this.onMapMovedTimer = setTimeout(() => {
        const map = this.$refs[this.mapCacheKey]?.Get();
        if (!map || !map.host || !map.leaflet) {
          return;
        }
        const zoomLevel = map.GetZoom();
        const newVal = !(zoomLevel && zoomLevel > 9);
        if (newVal !== this.renderDots) {
          this.renderDots = newVal;
          if (this.useGeoList) {
            this.renderAllGeos();
          } else if (this.defaultToZips) {
            this.renderAllZips({ clear: true });
          }
        }
      }, 500);
    },
    loadDMAs(list: string[]): void {
      if (this.demographicsOnly) {
        return;
      }
      if (!list || list.length === 0) {
        this.loading = false;
        this.loadedValidData = false;
        // eslint-disable-next-line no-console
        console.log('loadDMAs: empty dma list');
        return;
      }
      this.loading = true;
      this.loadedValidData = true;
      this.geoPolygons = {};
      this.$store
        .dispatch('getPolygons', { dmacodes: list })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          this.loading = false;
          if (!data) {
            // eslint-disable-next-line no-console
            console.log('loadDMAs getPolygons result:', ERRORS.NO_POLYGON_RESPONSE);
            // this.$store.dispatch('showError', ERRORS.NO_POLYGON_RESPONSE);
            // this.emitRendered(true, null, this.mapCacheKey);
            // return;
          }

          Object.keys(data).forEach((k: string) => {
            const poly = data[k];
            if (k === 'LocationNotFound') {
              if (!this.isExporting) {
                // eslint-disable-next-line no-console
                console.log('polygons not found', poly);
              }
              return;
            }
            this.geoPolygons[k] = poly;
          });
          if (Object.keys(this.geoPolygons).length === 0) {
            this.loadedValidData = false;
            // eslint-disable-next-line no-console
            console.log('loadDMAs getPolygons after mapping', ERRORS.NO_POLYGONS);
            // this.$store.dispatch('showError', ERRORS.NO_POLYGONS);
            // this.emitRendered(true, null, this.mapCacheKey);
            // return;
          }
          setTimeout(() => {
            // leave some breathing room, the recycled map need to be moved back into the current DOM
            this.renderDMAs({ doneRendering: true });
          }, 20);
        })
        .catch((error: Error) => {
          this.loading = false;
          // this.loadedValidData = false;
          // this.$store.dispatch('showError', error);
          console.error('getPolygons fetch error', error);
          this.emptyPolygonsResponse = true;
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          // eslint-disable-next-line no-console
        });
    },
    renderDMAs(): void {
      if (this.demographicsOnly) {
        return;
      }
      const dmaGradient = this.Theme?.polygons?.light?.dmaGradient;
      if (!this.mapReady || !dmaGradient) {
        return;
      }

      const gradient = gradstop({
        stops: 101,
        inputFormat: 'hex',
        colorArray: dmaGradient || ['#B1C5CE', '#03A9F4', '#01579B'],
      });

      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      this.emitRendered(true, map, this.mapCacheKey);

      this.activeDMA = null;
      map.ClearMap();
      const dmasLayer = map.CreateLayer('DMAs');
      let hasData = false;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dmaMenuList: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const selectDmas: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const topDMAIDs: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const topDMALayers: any[] = [];
      if (!this.activeDMA) {
        // find the top 90% DMAs, zoom on them
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let sorted: any[] = [];
        Object.keys(this.geoLookupByID).forEach((k: string) => {
          const x = this.geoLookupByID[k];
          sorted.push({ score: x.score, id: x.DMA });
        });
        sorted = sorted
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((x: any) => x.id !== '0')
          .sort((a, b) => (a.score > b.score ? -1 : b.score > a.score ? 1 : 0));

        const add = (acc, dma) => acc + dma.score;
        const total = sorted.reduce(add, 0);
        const threshold = (total * 90.0) / 100.0;
        let i = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sorted.forEach((k: any) => {
          if (i > threshold) {
            return;
          }
          i += k.score;
          topDMAIDs.push(k.id);
        });
      }
      Object.keys(this.geoPolygons).forEach((dmaName: string) => {
        const dmaPoly = this.geoPolygons[dmaName];
        const geoData = this.geoLookupByID[dmaPoly.DMA_CODE];
        // console.log('geoData is', geoData);
        const dmaKey = `${dmaPoly.DMA_CODE}`;
        if (!geoData || !dmaPoly.WKT_P100) {
          return;
        }
        dmaMenuList.push({ title: geoData.DMAName, id: geoData.DMA });
        if (this.selectedDMAs && this.selectedDMAs.length > 0) {
          if (this.selectedDMAs.includes(dmaKey)) {
            selectDmas.push(geoData);
            // isSelectedDMA = true;
            if (this.selectedDMAs.length === 1) {
              this.activeDMA = geoData;
            }
          } else {
            return;
          }
        }
        const layer = map.AddToLayer(dmasLayer);
        map.SetWKT(layer, dmaPoly.WKT_P100, true);
        if (topDMAIDs.includes(geoData.DMA)) {
          topDMALayers.push(layer);
        }
        const zIndex = Math.floor(100 * geoData.score);
        layer.setZIndex(zIndex + 1);
        const color = gradient[Math.floor((geoData.score / this.geoMaxValue) * 100.0)];
        const color1 = gradient[99];
        const color2 = gradient[50];
        const color3 = gradient[1];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const style: any = this.activeDMA
          ? { ...this.Theme?.polygons?.light?.dmaSelected, color: color2, weight: 2 }
          : { ...this.Theme?.polygons?.light?.dma, color: color2, fillColor: color };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const hoverStyle: any = { ...this.Theme?.polygons?.light?.dmaHover, color: color1, fillColor: color3 };

        layer.setStyle(style);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let layerPopup: any = null;
        layer
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mouseover', (e: any) => {
            if (!this.activeDMA) {
              layer.setStyle(hoverStyle);
            }
            // console.log('geoData is', geoData);
            const textContent =
              geoData.score >= 0
                ? `${geoData.DMAName}: ${geoData.score.toFixed(2)}%`
                : `${geoData.DMAName} has no data`;
            layerPopup = map.CreatePopup(textContent, e.latlng);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mousemove', (e: any) => {
            if (layerPopup) {
              layerPopup.setLatLng(e.latlng);
            }
          })
          .on('mouseout', () => {
            layer.setStyle(style);
            if (layerPopup) {
              map.ClosePopup(layerPopup);
              layerPopup = null;
            }
          })
          .on('click', () => {
            if (!this.dmaHasData(geoData)) return;
            if (this.$route.query.dmas !== geoData.DMA) {
              this.$router.push({ query: { ...this.$route.query, dmas: geoData.DMA } });
            }
          });
        hasData = true;
      });
      dmaMenuList.push({ title: 'All DMAs', id: 'all' });
      this.dmaPickerList = dmaMenuList;
      if (this.selectedDMAs && this.selectedDMAs.length > 0) {
        if (this.selectedDMAs.length === 1 && selectDmas.length === 1) {
          this.dmaPickerTitle = selectDmas[0].DMAName;
          if (this.activeDMA) {
            this.loadDMAZips();
          }
        } else {
          this.dmaPickerTitle = 'selection';
        }
      } else {
        this.dmaPickerTitle = 'All DMAs';
      }
      if (!hasData) {
        return;
      }
      map.SetRenderCacheKey(this.mapRenderCacheKey);
      let paddingOptions = { paddingTopLeft: [20, 20], paddingBottomRight: [20, 20] };
      if (!this.onlyMap) {
        paddingOptions = { ...paddingOptions, paddingTopLeft: [400, 20], paddingBottomRight: [20, 20] };
      }
      setTimeout(() => {
        this.setXportingState() || map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
      }, 10);

      this.debounceRefreshMap();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dmaHasData(thinDMA: any): boolean {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let dma: any = null;
      if (thinDMA.id) {
        if (thinDMA.id === 'all') {
          return true;
        }
        dma = this.geoLookupByID[thinDMA.id];
      } else if (thinDMA.ZipRate) {
        dma = thinDMA;
      }
      if (!dma || !dma.ZipRate) {
        return false;
      }
      if (Array.isArray(dma.ZipRate) && dma.ZipRate.length > 0) {
        return true;
      }
      if (typeof dma.ZipRate === 'object' && dma.ZipRate.Zipcode) {
        return true;
      }
      return false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pickDMA(dma: any): void {
      if (!this.dmaHasData(dma)) return;

      if (!dma || !dma.id || (dma && dma.id && dma.id === 'all')) {
        if (this.$route.query.dmas) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          const { dmas: _, ...clean } = this.$route.query;
          this.$router.push({ query: clean });
        }
        if (dma.id === 'all') {
          this.$store.dispatch('selectAllDMAs', { selectAll: true, fromMap: true });
        }
      } else if (this.$route.query.dmas !== dma.id) {
        this.$router.push({ query: { ...this.$route.query, dmas: dma.id } });
      }
    },
    seeAllDMAs(): void {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { dmas, ...clean } = this.$route.query;
      this.$router.push({ query: clean });
    },
    loadDMAZips(): void {
      if (this.demographicsOnly) {
        return;
      }
      if (!this.activeDMA || !this.activeDMA.ZipRate) {
        return;
      }
      const zipRates = Array.isArray(this.activeDMA.ZipRate) ? this.activeDMA.ZipRate : [this.activeDMA.ZipRate];
      const zipList = zipRates
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((z: any) => z.Zipcode)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((z: any) => z.Zipcode);

      this.loading = true;
      this.$store
        .dispatch('getPolygons', { zips: zipList })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          if (!data) {
            // eslint-disable-next-line no-console
            console.log('loadDMAZips getPolygons response:', 'no polygon response');
            // this.$store.dispatch('showError', 'no zip polygon');
            this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          }

          this.loading = false;
          const map = this.$refs[this.mapCacheKey]?.Get();
          this.zipPolygons = {};
          this.zipLookupByID = {};
          this.zipMaxRate = 0;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          zipRates.forEach((z: any) => {
            let score = parseFloat(z.Rate);
            if (Number.isNaN(score) || score < 0) {
              score = 0;
            } else if (score > this.zipMaxRate) {
              this.zipMaxRate = score;
            }
            this.zipLookupByID[z.Zipcode] = { ...z, score };
          });

          if (map) {
            Object.keys(data).forEach((k: string) => {
              const poly = data[k];
              if (k === 'LocationNotFound') {
                if (!this.isExporting) {
                  // eslint-disable-next-line no-console
                  console.log('polygons not found', poly);
                }
                return;
              }
              if (poly.WKT_P100) {
                this.zipPolygons[k] = poly;
              }
            });
            if (Object.keys(this.zipPolygons).length === 0) {
              // eslint-disable-next-line no-console
              console.log('loadDMAZips getPolygons after mapping', ERRORS.NO_POLYGONS);
              this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
              // this.$store.dispatch('showError', ERRORS.NO_POLYGONS);
              // this.emitRendered(true, map, this.mapCacheKey);
              // return;
            }
            this.renderDMAZips();
          }
        })
        .catch((error: Error) => {
          // this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error('getPolygons fetch error', error);
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          this.emptyPolygonsResponse = true;
        });
    },
    loadAllZips(): void {
      if (this.demographicsOnly) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let allZips: any[] = [];
      if (this.geoData && Array.isArray(this.geoData) && this.geoData.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.geoData.forEach((dma: any) => {
          if (dma.ZipRate && Array.isArray(dma.ZipRate)) {
            allZips = allZips.concat(dma.ZipRate);
          } else if (dma.ZipRate && dma.ZipRate.Zipcode) {
            allZips.push(dma.ZipRate);
          }
        });
      }

      const zipList = allZips
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((z: any) => z.Zipcode)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((z: any) => z.Zipcode);

      if (zipList.length === 0) {
        // fall back to render DMAs
        const dmaList = this.geoData
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((dma: any) => dma.DMAName && dma.DMAName !== 'Other')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((dma: any) => dma.DMA);
        this.defaultToZips = false;
        this.loadDMAs(dmaList);
        return;
      }

      this.zipLookupByID = {};
      this.zipMaxRate = 0;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      allZips.forEach((z: any) => {
        // ignore 'other' zip codes
        if (utils.isUnknownOrOtherOrInvalidString(z.Zipcode)) {
          return;
        }
        let score = parseFloat(z.Rate);
        if (Number.isNaN(score) || score < 0) {
          score = 0;
        } else if (score > this.zipMaxRate) {
          this.zipMaxRate = score;
        }
        this.zipLookupByID[z.Zipcode] = { ...z, score };
      });

      this.loadedValidData = true;
      this.loading = true;

      // see if we already have lat-long
      if (allZips[0].Lat && allZips[0].Long) {
        this.loading = false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        allZips.forEach((z: any) => {
          if (z.Lat && z.Long) {
            this.zipPolygons[z.Zipcode] = {
              LAT: z.Lat,
              LONG: z.Long,
            };
          }
        });
        this.renderDots = true;
        this.renderAllZips({ clear: true });
      }

      this.$store
        .dispatch('getPolygons', { zips: zipList })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          this.loading = false;
          if (!data) {
            // eslint-disable-next-line no-console
            console.log('loadAllZips getPolygons response', 'no polygon response');
            // this.$store.dispatch('showError', 'no zip polygon');
            this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          }
          this.zipPolygons = {};

          Object.keys(data).forEach((k: string) => {
            const poly = data[k];
            if (k === 'LocationNotFound') {
              if (!this.isExporting) {
                // eslint-disable-next-line no-console
                console.log('polygons not found', poly);
              }
              return;
            }
            if (poly.WKT_P100) {
              this.zipPolygons[k] = poly;
            }
          });

          if (Object.keys(this.zipPolygons).length === 0) {
            // eslint-disable-next-line no-console
            console.log('loadAllZips getPolygons after mapping', ERRORS.NO_POLYGONS);
            // this.$store.dispatch('showError', ERRORS.NO_POLYGONS);
            // this.emitRendered(true, null, this.mapCacheKey);
            // return;
          }

          this.renderAllZips({ clear: true, doneRendering: true });
        })
        .catch((error: Error) => {
          // this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error('getPolygons fetch error', error);
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          this.emptyPolygonsResponse = true;
        });
    },
    loadAllGeos(): void {
      if (this.demographicsOnly) {
        return;
      }
      if (this.geoData && Array.isArray(this.geoData) && this.geoData.length > 0) {
        this.loadedValidData = true;
        this.loading = true;
      } else {
        // eslint-disable-next-line no-console
        console.error('loadAllGeos empty');
        return;
      }

      // see if we already have lat-long
      if (this.geoData[0].Lat && this.geoData[0].Long) {
        this.loading = false;
        this.renderDots = true;
        this.renderAllGeos({ clear: true });
      }

      if (this.loadGeofencingImpressions) return;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const geoKeys: any = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.geoData.forEach((geo: any) => {
        // ignore 'other' zip codes
        if (geo.Zipcode && utils.isUnknownOrOtherOrInvalidString(geo.Zipcode)) {
          return;
        }

        if (!geo.GeoParamKey) {
          if (!this.isExporting) {
            // eslint-disable-next-line no-console
            console.error('geoList without a key', geo);
          }
          return;
        }
        if (!geo.GeoParamVal) {
          if (!this.isExporting) {
            // eslint-disable-next-line no-console
            console.error('geoList without a name', geo);
          }
          return;
        }
        if (!geoKeys[geo.GeoParamKey]) {
          geoKeys[geo.GeoParamKey] = [];
        }
        if (geoKeys[geo.GeoParamKey].length < 500 && !geoKeys[geo.GeoParamKey].includes(geo.GeoParamVal)) {
          geoKeys[geo.GeoParamKey].push(geo.GeoParamVal);
        }
      });

      this.$store
        .dispatch('getPolygons', geoKeys)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          this.loading = false;
          if (!data) {
            // eslint-disable-next-line no-console
            console.log('loadAllGeos getPolygons response:', 'no polygon response');
          }
          this.geoPolygons = {};

          Object.keys(data).forEach((k: string) => {
            const poly = data[k];
            if (k === 'LocationNotFound') {
              if (!this.isExporting) {
                // eslint-disable-next-line no-console
                console.log('polygons not found', poly);
              }
              return;
            }
            if (poly.WKT_P100) {
              if (poly.ITYPE === 'DMA') {
                this.geoPolygons[poly.DMA_CODE] = poly;
              } else {
                this.geoPolygons[k] = poly;
              }
            }
          });

          if (Object.keys(this.geoPolygons).length === 0) {
            // eslint-disable-next-line no-console
            console.log('loadAllGeos getPolygons after mapping:', ERRORS.NO_POLYGONS);
            // If valid geoData, don't display error message. AmazonOTT request.
            // if (!this.geoData?.length) this.$store.dispatch('showError', ERRORS.NO_POLYGONS);
            // this.emitRendered(true, null, this.mapCacheKey);
          }
          // console.log('DMAZipMap polygons loaded');
          this.renderAllGeos({ clear: true, adjustView: true, doneRendering: true });
        })
        .catch((error: Error) => {
          // this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error('getPolygons fetch error', error);
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          this.emptyPolygonsResponse = true;
        });
    },
    renderDMAZips(): void {
      if (this.demographicsOnly) {
        return;
      }
      if (!this.mapReady) {
        setTimeout(() => {
          this.renderDMAZips();
        }, 250);
        return;
      }

      const gradient = gradstop({
        stops: 101,
        inputFormat: 'hex',
        colorArray: this.Theme?.polygons?.light?.dmaGradient || ['#B1C5CE', '#03A9F4', '#01579B'],
      });

      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      const zipsLayer = map.CreateLayer('ZIPs');
      Object.keys(this.zipPolygons).forEach((zipCode: string) => {
        const zipPoly = this.zipPolygons[zipCode];
        const zipData = this.zipLookupByID[zipCode]; // { "Rate": "100%", "Zipcode": "15068" }
        if (!zipData || !zipPoly.WKT_P100) {
          return;
        }
        const layer = map.AddToLayer(zipsLayer);
        map.SetWKT(layer, zipPoly.WKT_P100, true);

        const zIndex = Math.floor(99.0 * zipData.score);
        layer.setZIndex(zIndex + 1);
        const color = gradient[Math.floor((zipData.score / this.zipMaxRate) * 100.0)];
        const color2 = gradient[50];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const style: any = { ...this.Theme?.polygons?.light?.dma, color: color2, fillColor: color };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const hoverStyle: any = { ...this.Theme?.polygons?.light?.dmaHover, color: color2, fillColor: color };

        layer.setStyle(style);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let layerPopup: any = null;
        layer
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mouseover', (e: any) => {
            layer.setStyle(hoverStyle);
            // console.log('zipData is', zipData);
            if (zipData.score > 0) {
              const textContent = `${zipData.Zipcode}: ${zipData.score.toFixed(2)}%`;
              layerPopup = map.CreatePopup(textContent, e.latlng);
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mousemove', (e: any) => {
            if (layerPopup) {
              layerPopup.setLatLng(e.latlng);
            }
          })
          .on('mouseout', () => {
            layer.setStyle(style);
            if (layerPopup) {
              map.ClosePopup(layerPopup);
              layerPopup = null;
            }
          });
      });
    },
    renderAllZips(
      { clear, redrawing, doneRendering } = { clear: false, redrawing: false, doneRendering: false },
    ): void {
      if (this.demographicsOnly) {
        return;
      }
      if (!this.mapReady || this.loading) {
        setTimeout(() => {
          this.renderAllZips({ clear, redrawing, doneRendering });
        }, 250);
        return;
      }

      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map, this.mapCacheKey);
        setTimeout(() => {
          this.renderAllZips({ clear, redrawing, doneRendering });
        }, 1000);
        return;
      }

      this.emitRendered(doneRendering, map, this.mapCacheKey);

      if (clear) {
        map.ClearMap();
      }

      let polygonList = Object.keys(this.zipPolygons);
      let maxPolygonLimit = 100;
      if (this.componentConfig.layerLimit && this.componentConfig.layerLimit > 0) {
        maxPolygonLimit = this.componentConfig.layerLimit;
      }

      let hideZeros = !!this.componentConfig.hideZeros;
      let useDots = this.renderDots;

      const zoomLevel = map.GetZoom();
      if (zoomLevel && zoomLevel > 9) {
        useDots = false;
        hideZeros = false;
      }

      if (Object.keys(polygonList).length <= 10) {
        useDots = false;
      }

      if (!useDots) {
        // remove limit when zoomed in enough
        maxPolygonLimit = polygonList.length;
        // let's still put a limit for now, just in case
        if (maxPolygonLimit > 200) {
          maxPolygonLimit = 200;
        }
        hideZeros = false;
      } else if (maxPolygonLimit < 500) {
        // we can show more dots than polygons
        maxPolygonLimit = 500;
      }

      // set max polygon to 1000 to release DASH-5123 before big map refactor DASH-5059
      maxPolygonLimit = 1000;

      if (polygonList.length > maxPolygonLimit) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let sortedDescByScore: any[] = [];
        Object.keys(this.zipLookupByID).forEach((k: string) => {
          const x = this.zipLookupByID[k];
          sortedDescByScore.push({ s: x.score, z: x.Zipcode });
        });

        sortedDescByScore = sortedDescByScore.sort((a, b) => b.s - a.s);

        polygonList = sortedDescByScore.slice(0, maxPolygonLimit).map(x => x.z);
        if (this.componentConfig.legend) {
          this.legend = this.componentConfig.legend + ` (top ${maxPolygonLimit})`;
        }
      } else if (this.componentConfig.legend) {
        this.legend = this.componentConfig.legend;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let topScoringZips: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const topScoringLayers: any[] = [];
      if (
        this.componentConfig.zoomToTopNPercents &&
        this.componentConfig.zoomToTopNPercents > 0 &&
        this.componentConfig.zoomToTopNPercents < 100
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let sortedDescByScore: any[] = [];
        Object.keys(this.zipLookupByID).forEach((k: string) => {
          const x = this.zipLookupByID[k];
          sortedDescByScore.push({ s: x.score, z: x.Zipcode });
        });

        sortedDescByScore = sortedDescByScore.sort((a, b) => b.s - a.s);

        const add = (acc, x) => acc + x.s;
        const total = sortedDescByScore.reduce(add, 0);
        const threshold = (total * this.componentConfig.zoomToTopNPercents) / 100.0;
        let i = 0;
        let cutScoreAt = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sortedDescByScore.forEach((k: any) => {
          if (topScoringZips.length > 2 && i > threshold && cutScoreAt && k.score < cutScoreAt) {
            // return;
          }
          i += k.s;
          cutScoreAt = k.s;
          topScoringZips.push(k);
        });
        // to be fair, remove all zips that have the cutting score
        const fair = topScoringZips.filter(k => k.s > cutScoreAt);
        if (fair.length > 2) {
          //  topScoringZips = fair;
        }
        topScoringZips = topScoringZips.map(k => k.z);
      }

      const gradient = gradstop({
        stops: 101,
        inputFormat: 'hex',
        colorArray: this.Theme?.polygons?.light?.dmaGradient || ['#B1C5CE', '#03A9F4', '#01579B'],
      });

      const zipsLayer = map.CreateLayer('ZIPs');
      let hasPolygons = false;
      polygonList.forEach((zipCode: string) => {
        const zipPoly = this.zipPolygons[zipCode];
        const zipData = this.zipLookupByID[zipCode]; // { "Rate": "100%", "Zipcode": "15068" }
        if (!zipPoly || !zipData || (hideZeros && !zipData.score)) {
          return;
        }
        const zIndex = Math.floor(99.0 * zipData.score);
        let layer, style, hoverStyle, color;
        if (useDots || !zipPoly.WKT_P100) {
          const lat = parseFloat(zipPoly.LAT);
          const lon = parseFloat(zipPoly.LONG);
          if (isNaN(lat) || isNaN(lon) || lat === 0 || lon === 0) {
            // console.log('zipPoly no lat/lon', zipPoly, lat, lon);
            return;
          }
          layer = map.AddDivToLayer({ className: 'fenceIcon', zIndexOffset: zIndex }, lat, lon, zipsLayer);
          color = gradient[Math.floor((zipData.score / this.zipMaxRate) * 100.0)];
          if (layer._icon) {
            // layer._icon.style.opacity = 0.7 + 0.3 * zipData.score;
            layer._icon.style.background = color;
          } else {
            setTimeout(() => {
              if (layer._icon) {
                // layer._icon.style.opacity = 0.7 + 0.3 * zipData.score;
                layer._icon.style.background = color;
              }
            }, 200);
          }
        } else {
          layer = map.AddToLayer(zipsLayer);
          map.SetWKT(layer, zipPoly.WKT_P100, true);

          layer.setZIndex(zIndex + 1);
          color = gradient[Math.floor((zipData.score / this.zipMaxRate) * 100.0)];
          const color2 = gradient[50];

          style = { ...this.Theme?.polygons?.light?.dma, color: color2, fillColor: color };

          hoverStyle = { ...this.Theme?.polygons?.light?.dmaHover, color: color2, fillColor: color };
          layer.setStyle(style);
        }

        if (!layer) {
          // eslint-disable-next-line no-console
          console.error('failed to create map layer');
          return;
        }

        if (topScoringZips.includes(zipCode)) {
          topScoringLayers.push(layer);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let layerPopup: any = null;
        hasPolygons = true;
        let hovered = false;
        layer
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mouseover', (e: any) => {
            this.hoveredGeo = zipData;
            if (!layer) {
              return;
            }
            if (layer._icon) {
              // layer._icon.style.opacity = 1;
            } else if (layer.setStyle) {
              layer.setStyle(hoverStyle);
            }
            // const textContent = `${zipData.Zipcode}: ${zipData.score.toFixed(2)}%`;
            // let textContent = zipData.Zipcode;
            // if (zipData.Impressions > 1) {
            //   textContent += `: ${zipData.Impressions} impressions`;
            // } else if (zipData.Impressions > 0) {
            //   textContent += ': 1 impression';
            // } else {
            //   textContent += ': no impression';
            // }
            // layerPopup = map.CreatePopup(textContent, e.latlng, { closeButton: false });
            hovered = true;
            if (!hovered) {
              return;
            }
            this.hoveredZipText[0] = 'Zip Code:';

            let hoverText = '';
            if (this.sectionConfig.id === 'gtdisplay' || this.sectionConfig.id === 'gtvideo') {
              hoverText = 'Geofencing';
              if (zipData.Impressions === 1) {
                this.hoveredZipText[1] = hoverText + '<br/> Impression:';
              } else {
                this.hoveredZipText[1] = hoverText + '<br/> Impressions:';
              }
              this.hoveredZipText[2] = '<br/>' + utils.formatNumberWithCommas(zipData.Impressions);
            } else {
              if (zipData.Impressions === 1) {
                this.hoveredZipText[1] = 'Impression:';
              } else {
                this.hoveredZipText[1] = 'Impressions:';
              }
              this.hoveredZipText[2] = utils.formatNumberWithCommas(zipData.Impressions);
            }
            setTimeout(() => {
              const template = this.$refs.mapToolTip?.innerHTML || '';
              layerPopup = map.CreatePopup(template, e.latlng, { closeButton: false, offset: [2, 10] });
            }, 500);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mousemove', (e: any) => {
            if (layerPopup) {
              layerPopup.setLatLng(e.latlng);
            }
          })
          .on('mouseout', () => {
            if (!layer) {
              return;
            }
            hovered = false;
            if (layer._icon) {
              // layer._icon.style.opacity = 0.7 + 0.3 * zipData.score;
            } else if (layer.setStyle) {
              layer.setStyle(style);
            }
            if (layerPopup) {
              map.ClosePopup(layerPopup);
              layerPopup = null;
            }
          });
      });

      map.SetRenderCacheKey(this.mapRenderCacheKey);
      if ((map.initialized && clear) || !hasPolygons) {
        // don't reposition the map if we're updating/clearing the current map
        return;
      }

      let paddingOptions = { paddingTopLeft: [20, 20], paddingBottomRight: [20, 20] };
      if (!this.onlyMap) {
        paddingOptions = { ...paddingOptions, paddingTopLeft: [400, 20] };
      }

      if (map.initialized) {
        setTimeout(() => {
          if (this.setXportingState()) {
            return;
          }
          if (topScoringLayers.length > 1) {
            map.FlyToLayers(topScoringLayers, { force: true, animate: false, ...paddingOptions });
          } else {
            map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
          }
        }, 10);
      } else {
        setTimeout(() => {
          if (this.setXportingState()) {
            return;
          }
          if (topScoringLayers.length > 1) {
            map.FlyToLayers(topScoringLayers, { force: true, animate: false, ...paddingOptions });
          } else {
            map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
          }
        }, 10);
      }

      this.debounceRefreshMap();
    },
    renderAllGeos(
      { clear, redrawing, adjustView, retries, doneRendering } = {
        clear: false,
        redrawing: false,
        adjustView: false,
        retries: 100,
        doneRendering: false,
      },
    ): void {
      if (this.demographicsOnly) {
        return;
      }
      if (!this.mapReady || this.loading) {
        setTimeout(() => {
          this.renderAllGeos({ clear, redrawing, adjustView, retries: retries - 1, doneRendering });
        }, 250);
        return;
      }

      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map || !map.host || !map.leaflet) {
        if (retries < 0) {
          // eslint-disable-next-line no-console
          console.error('map component did not load correctly', map, this.mapCacheKey);
          return;
        }
        setTimeout(() => {
          this.renderAllGeos({ clear, redrawing, adjustView, retries: retries - 1, doneRendering });
        }, 250);
        return;
      }

      this.emitRendered(doneRendering, map, this.mapCacheKey);
      // console.log('renderAllGeos', { clear, redrawing, adjustView }, this.mapRenderCacheKey, map.renderCacheKey);

      map.ClearMap();

      let polygonKeyList = Object.keys(this.geoLookupByID);
      let maxPolygonLimit = 100;
      if (this.componentConfig.layerLimit && this.componentConfig.layerLimit > 0) {
        maxPolygonLimit = this.componentConfig.layerLimit;
      }

      let hideZeros = !!this.componentConfig.hideZeros;
      let useDots = this.renderDots;

      const zoomLevel = map.GetZoom();
      if (zoomLevel && zoomLevel > 9) {
        useDots = false;
        hideZeros = false;
      }

      if (polygonKeyList.length > 0 && polygonKeyList.length <= 50) {
        useDots = false;
      }

      if (!useDots) {
        // remove limit when zoomed in enough
        maxPolygonLimit = polygonKeyList.length;
        hideZeros = false;
      } else if (maxPolygonLimit < 500) {
        // we can show more dots than polygons
        maxPolygonLimit = 500;
      }

      // set max polygon to 1000 to release DASH-5123 before big map refactor DASH-5059
      maxPolygonLimit = 1000;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let topScoringGeos: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const topScoringLayers: any[] = [];
      if (
        this.componentConfig.zoomToTopNPercents &&
        this.componentConfig.zoomToTopNPercents > 0 &&
        this.componentConfig.zoomToTopNPercents < 100
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let sortedDescByScore: any[] = [];
        Object.keys(this.geoLookupByID).forEach((k: string) => {
          const x = this.geoLookupByID[k];
          sortedDescByScore.push({ s: x.Impressions || x.PageViews, k });
        });

        sortedDescByScore = sortedDescByScore.sort((a, b) => b.s - a.s);

        const add = (acc, x) => acc + x.s;
        const total = sortedDescByScore.reduce(add, 0);
        const threshold = (total * this.componentConfig.zoomToTopNPercents) / 100.0;
        let i = 0;
        let cutScoreAt = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sortedDescByScore.forEach((k: any) => {
          if (topScoringGeos.length > 2 && i > threshold && cutScoreAt && k.score < cutScoreAt) {
            // return;
          }
          i += k.s;
          cutScoreAt = k.s;
          topScoringGeos.push(k);
        });
        // to be fair, remove all geos that have the cutting number of impressions
        const fair = topScoringGeos.filter(k => k.s > cutScoreAt);
        if (fair.length > 2) {
          //  topScoringGeos = fair;
        }
        topScoringGeos = topScoringGeos.map(kv => kv.k);
      }

      // sort polygons by their zIndex, to render them in the right order
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let sorted: any[] = [];
      Object.keys(this.geoLookupByID).forEach((k: string) => {
        // try to never show dots for states and DMAs
        // order layers: high score polygons *below* low score polygons *below* low score dots *below* high score dots
        const geoData = this.geoLookupByID[k];
        let score = geoData.Impressions || geoData.PageViews;
        score = Math.floor((1000.0 * score) / this.geoMaxValue);
        geoData.score1000 = score;
        geoData.score100 = Math.floor(score / 10.0);
        geoData.useDot = false;
        switch (geoData.GeoParamKey) {
          case 'states':
            score = 2000 - score;
            break;
          case 'dmacodes':
            score = 3000 - score;
            break;
          case 'cities':
            if (useDots) {
              geoData.useDot = true;
              score = 4000 + score;
            } else {
              score = 4000 - score;
            }
            break;
          case 'zips':
            // cities and zip share the same score offset, as they can be smaller/bigger than other cities/zips
            if (useDots) {
              geoData.useDot = true;
              score = 4000 + score;
            } else {
              score = 4000 - score;
            }
            break;
          default:
            score = score + 5000;
            break;
        }
        sorted.push({ s: score, k });
      });
      sorted = sorted.sort((a, b) => (a.s > b.s ? 1 : b.s > a.s ? -1 : 0));
      polygonKeyList = sorted.map(kv => kv.k);

      if (polygonKeyList.length > maxPolygonLimit) {
        polygonKeyList = polygonKeyList.slice(-1 * maxPolygonLimit);
        if (this.componentConfig.legend) {
          this.legend = this.componentConfig.legend + ` (top ${maxPolygonLimit})`;
        }
      } else if (this.componentConfig.legend) {
        this.legend = this.componentConfig.legend;
      }

      let gradientColors = ['#B1C5CE', '#03A9F4', '#01579B'];
      if (this.Theme?.polygons?.light?.dmaGradient) {
        gradientColors = this.Theme?.polygons?.light?.dmaGradient;
      }
      if (this.componentConfig.mapGradient) {
        gradientColors = [...this.componentConfig.mapGradient];
      } else if (this.componentConfig.gradient) {
        gradientColors = [...this.componentConfig.gradient];
      }

      const gradient = gradstop({
        stops: 101,
        inputFormat: 'hex',
        colorArray: gradientColors,
      });

      const outerMarketsLayer = map.CreateLayer('outerMarket');

      polygonKeyList.forEach((key: string) => {
        const geoData = this.geoLookupByID[key];
        let layer, hovered, layerPopup;
        if (geoData.outerMarket) {
          const om = geoData.outerMarket;
          om.PopupTitle = `Outer Market: ${om.name} ${om.League} ${om.Team}`;
          const geoPoly = this.geoPolygons[om.GeoParamVal];
          if (geoPoly) {
            layer = map.AddToLayer(outerMarketsLayer);
            // console.log('rendering polygon', key);
            map.SetWKT(layer, geoPoly.WKT_P100, true);
            layer.setStyle(this.Theme?.polygons?.light?.outerMarket);
            this.dmaMarketsHash[geoData.outerMarket.GeoKey] = layer;
            layer
              .on('mouseover', () => {
                this.hoveredGeo = om;
                if (!layer) {
                  return;
                }
                hovered = true;
                layer.setStyle(this.Theme?.polygons?.light?.outerMarketHover);
                this.hoveredZipText[0] = '';
                this.hoveredZipText[1] = 'Impressions:';
                this.hoveredZipText[2] = utils.formatNumberWithCommas(om.Impressions);
                const lat = parseFloat(geoData.Lat);
                const lon = parseFloat(geoData.Long);
                let latlon;
                if (!isNaN(lat) && !isNaN(lon)) {
                  latlon = [lat, lon];
                }
                if (!latlon) {
                  return;
                }
                setTimeout(() => {
                  if (!hovered) {
                    return;
                  }
                  const content = this.$refs.rsnToolTip || this.$refs.mapToolTip;
                  if (!content) {
                    layerPopup = null;
                    return;
                  }
                  layerPopup = map.CreatePopup(content, latlon, {
                    closeButton: false,
                    offset: [2, 10],
                  });
                }, 10);
              })
              .on('mouseout', () => {
                if (!layer) {
                  return;
                }
                hovered = false;
                setTimeout(() => {
                  layer.setStyle(this.Theme?.polygons?.light?.outerMarket);
                  map.ClosePopup(layerPopup);
                  layerPopup = null;
                }, 50);
              });
          }
        }
      });

      const geosLayer = map.CreateLayer('GEOs');
      let hasPolygons = false;

      polygonKeyList.forEach((key: string) => {
        let tooltipFollowsCursor = false;
        let persistentTooltip = false;
        const geoData = this.geoLookupByID[key];
        const views = geoData.Impressions || geoData.PageViews;
        if (!geoData || (hideZeros && !views)) {
          return;
        }

        const geoPoly = this.geoPolygons[key];
        let layer, style, hoverStyle, color;
        if (geoData.useDot || !geoPoly?.WKT_P100) {
          // console.log('rendering dots', key);
          const lat = parseFloat(geoData.Lat);
          const lon = parseFloat(geoData.Long);
          if (isNaN(lat) || isNaN(lon) || lat === 0 || lon === 0) {
            // console.log('geoData no lat/lon', geoData, lat, lon);
            return;
          }

          const divIcon = { className: 'fenceIcon', zIndexOffset: geoData.score };
          if (geoData.isGeofencingLocation) {
            divIcon.className = `fenceIcon geofencingLocation ${this.getIconClassName(geoData.score)}`;
          }
          layer = map.AddDivToLayer(divIcon, lat, lon, geosLayer);

          color = gradient[geoData.score100];
          if (layer._icon) {
            layer._icon.style.background = color;
          } else {
            setTimeout(() => {
              if (layer._icon) {
                layer._icon.style.background = color;
              }
            }, 200);
          }
        } else {
          layer = map.AddToLayer(geosLayer);
          // console.log('rendering polygon', key);
          map.SetWKT(layer, geoPoly.WKT_P100, true);
          if (geoData.outerMarket && geoData.teams.length > 0) {
            this.dmaMarketsHash[geoData.teams[0].GeoKey] = layer;
          }

          color = gradient[geoData.score100];
          const color1 = gradient[1];
          const color50 = gradient[50];
          // const color99 = gradient[99];

          style = {
            ...this.Theme?.polygons?.light?.dma,
            color: color50,
            fillColor: color,
            weight: 1,
            fillOpacity: 0.3,
          };

          hoverStyle = {
            ...this.Theme?.polygons?.light?.dmaHover,
            color: color1,
            fillColor: color,
            weight: 5,
            fillOpacity: 0.7,
          };
          if (this.componentConfig.customStyle === 'ga') {
            style.stroke = false;
            if (this.componentConfig.mapGradientOpacity) {
              style.fillOpacity = 0.2 + (0.4 * geoData.score100) / 100.0;
            } else {
              style.fillOpacity = 0.6;
            }
            hoverStyle.stroke = false;
            hoverStyle.fillOpacity = 0.8;
          }
          if (
            (Array.isArray(geoData.teams) && geoData.teams.length > 0) ||
            (Array.isArray(geoData.legacy) && geoData.legacy.length > 0)
          ) {
            style = this.Theme?.polygons?.light?.rsn;
            hoverStyle = this.Theme?.polygons?.light?.rsnHover;
          }
          layer.setStyle(style);
        }

        if (!layer) {
          // eslint-disable-next-line no-console
          console.error('failed to create map layer');
          return;
        }

        if (topScoringGeos.includes(key)) {
          topScoringLayers.push(layer);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let layerPopup: any = null;
        hasPolygons = true;
        let hovered = false;
        layer
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mouseover', (e: any) => {
            this.hoveredGeo = geoData;
            if (!layer) {
              return;
            }
            if (layer._icon) {
              // layer._icon.style.opacity = 1;
            } else if (layer.setStyle) {
              layer.setStyle(hoverStyle);
            }

            hovered = true;
            this.hoveredZipText[0] = geoData.isGeofencingLocation ? geoData.AddressName : geoData.Type + ':';

            // For geofencing locations -> cites, we don't need to add text, will default to city name (geoLookupByID key)
            if (geoData.isGeofencingLocation && geoData.isCity) this.hoveredZipText[0] = '';

            let popupWidth;
            if (Array.isArray(geoData.teams) && geoData.teams.length > 0) {
              tooltipFollowsCursor = false;
              persistentTooltip = true;
              geoData.table.headers = [
                { text: 'Team', value: 'Team' },
                { text: 'League', value: 'League' },
                { text: 'Order', value: 'EstimateNumber' },
                { text: 'Imps', value: 'Impressions' },
              ];
              geoData.table.data = geoData.teams.map(x => ({
                key: x.GeoKey,
                Team: x.Team,
                League: x.League,
                EstimateNumber:
                  typeof x.EstimateNumber === 'undefined' || x.EstimateNumber === null ? 'N/A' : x.EstimateNumber,
                Impressions: utils.formatNumberWithCommas(x.Impressions),
              }));
              popupWidth = 440;
              if (Array.isArray(geoData.legacy) && geoData.legacy.length > 0) {
                const impressions = geoData.legacy.reduce((sum, geo) => sum + geo.Impressions, 0);
                if (impressions) {
                  geoData.table.data.push({
                    key: 'legacy',
                    Team: 'N/A',
                    League: 'N/A',
                    EstimateNumber: 'N/A',
                    Impressions: utils.formatNumberWithCommas(impressions),
                  });
                }
              }

              // DASH-3785: collapse lines with same EstimateNumber for RSN tactic
              if (this.sectionConfig.id === 'linear') {
                const tempData = [];
                for (const row of geoData.table.data) {
                  const capitalizedName = row.Team.toLowerCase()
                    .split(' ')
                    .map(name => name[0].toUpperCase() + name.substr(1))
                    .join(' ');
                  const valueKey = `${capitalizedName ?? 'N/A'}-${row.League ?? 'N/A'}-${row.EstimateNumber ?? 'N/A'}`;
                  if (tempData.find(obj => obj.valueKey === valueKey)) {
                    const existingRow = tempData.find(obj => obj.valueKey === valueKey);
                    existingRow.Impressions = utils.addNumbersWithCommas(existingRow.Impressions, row.Impressions);
                  } else {
                    tempData.push({
                      valueKey: valueKey,
                      Team: capitalizedName,
                      League: row.League,
                      EstimateNumber: row.EstimateNumber,
                      Impressions: row.Impressions,
                    });
                  }
                }
                geoData.table.data = tempData;
              }
            } else if (Array.isArray(geoData.legacy) && geoData.legacy.length > 0) {
              tooltipFollowsCursor = false;
              this.hoveredZipText[0] = geoData.name.includes('RSN') ? '' : 'DMA :';
              this.hoveredZipText[1] = 'Impressions:';
              if (geoData.Impressions === 1) {
                this.hoveredZipText[1] = 'Impression:';
              }
              const impressions = geoData.legacy.reduce((sum, geo) => sum + geo.Impressions, 0);
              this.hoveredZipText[2] = utils.formatNumberWithCommas(impressions);
            } else if ((geoData.Team || geoData.League) && geoData.Impressions) {
              this.hoveredZipText[0] = 'Home DMA - ';
              const labels = [];
              const values = [];
              if (geoData.Team) {
                labels.push('Team: ');
                values.push(geoData.Team);
              }
              if (geoData.League) {
                labels.push('League: ');
                values.push(geoData.League);
              }
              if (geoData.EstimateNumber) {
                labels.push('Order: ');
                values.push(geoData.EstimateNumber);
              }
              labels.push('Impressions: ');
              values.push(utils.formatNumberWithCommas(geoData.Impressions));

              this.hoveredZipText[1] = labels.join('<br/>');
              this.hoveredZipText[2] = values.join('<br/>');
            } else if (geoData.Impressions || geoData.Impressions === 0) {
              let hoverText = '';
              if (this.sectionConfig.id === 'gtdisplay' || this.sectionConfig.id === 'gtvideo') {
                hoverText = 'Geofencing ';
                this.hoveredZipText[1] = hoverText + '<br/>Impressions:';
                if (geoData.Impressions === 1) {
                  this.hoveredZipText[1] = hoverText + '<br/>Impression:';
                }
                this.hoveredZipText[2] = '<br/>' + utils.formatNumberWithCommas(geoData.Impressions);
              } else {
                this.hoveredZipText[1] = 'Impressions:';
                if (geoData.Impressions === 1) {
                  this.hoveredZipText[1] = 'Impression:';
                }
                this.hoveredZipText[2] = utils.formatNumberWithCommas(geoData.Impressions);
              }
              if (geoData.ImpressionsPercent) {
                this.hoveredZipText[1] += '<br/>Impression%:';
                this.hoveredZipText[2] += '<br/>' + geoData.ImpressionsPercent;
              }
              if (this.sectionConfig.id === 'sem') {
                this.hoveredZipText[1] += '<br/>Clicks:';
                this.hoveredZipText[2] += '<br/>' + geoData.Clicks;
              }
            } else if (geoData.GAUsers) {
              let hoverText1 = `User${geoData.GAUsers > 1 ? 's' : ''}:`;
              let hoverText2 = utils.formatNumberWithCommas(geoData.GAUsers);
              if (geoData.Sessions) {
                hoverText1 += `<br/>Session${geoData.Sessions > 1 ? 's' : ''}:`;
                hoverText2 += `<br/>${utils.formatNumberWithCommas(geoData.Sessions)}`;
              }
              if (geoData.PageViews) {
                hoverText1 += `<br/>Page View${geoData.PageViews > 1 ? 's' : ''}:`;
                hoverText2 += `<br/>${utils.formatNumberWithCommas(geoData.PageViews)}`;
              }
              this.hoveredZipText[1] = hoverText1;
              this.hoveredZipText[2] = hoverText2;
            }

            let latlon;
            if (tooltipFollowsCursor) {
              latlon = e.latlng;
            }
            if (!latlon || geoData.useDot) {
              // use the dot's center
              const lat = parseFloat(geoData.Lat);
              const lon = parseFloat(geoData.Long);
              if (!isNaN(lat) && !isNaN(lon)) {
                latlon = [lat, lon];
              }
            }
            if (!latlon) {
              // eslint-disable-next-line no-console
              console.log('creating popup without lat/lon', geoData);
              // return;
            }
            setTimeout(() => {
              if (!hovered) {
                return;
              }
              const content = this.$refs.rsnToolTip || this.$refs.mapToolTip;
              if (!content) {
                layerPopup = null;
                return;
              }
              layerPopup = map.CreatePopup(content, latlon, {
                closeButton: false,
                offset: [2, 10],
                minWidth: popupWidth,
                // bubblingMouseEvents: !persistentTooltip, does not work :(
              });
              const popupEl = layerPopup._wrapper; // this.$refs.rsnToolTip;
              popupEl.style.pointerEvents = 'none';
              if (persistentTooltip) {
                popupEl.style.pointerEvents = 'all';
                // console.log({ popupEl, layerPopup });
                popupEl.addEventListener(
                  'mousemove',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = true;
                  },
                  true,
                );
                popupEl.addEventListener(
                  'mouseover',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = true;
                  },
                  true,
                );
                popupEl.addEventListener(
                  'mouseout',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = false;
                    setTimeout(() => {
                      if (!hovered) {
                        map.ClosePopup(layerPopup);
                        layerPopup = null;
                      }
                    }, 250);
                  },
                  true,
                );
              }
            }, 10);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mousemove', (e: any) => {
            if (tooltipFollowsCursor && !geoData.useDot && layerPopup) {
              layerPopup.setLatLng(e.latlng);
            }
          })
          .on('mouseout', () => {
            if (!layer) {
              return;
            }
            hovered = false;
            setTimeout(() => {
              if (layer._icon) {
              } else if (layer.setStyle) {
                layer.setStyle(style);
              }
              if (!persistentTooltip && layerPopup) {
                map.ClosePopup(layerPopup);
                layerPopup = null;
              }
            }, 50);
          });
      });

      map.SetRenderCacheKey(this.mapRenderCacheKey);
      if ((map.initialized && clear && !adjustView) || !hasPolygons) {
        return;
      }

      let paddingOptions = { paddingTopLeft: [20, 20], paddingBottomRight: [20, 20] };
      if (!this.onlyMap) {
        paddingOptions = { ...paddingOptions, paddingTopLeft: [400, 20] };
      }

      if (!map.initialized) {
        setTimeout(() => {
          if (this.setXportingState()) {
            return;
          }
          if (topScoringLayers.length > 1) {
            map.FlyToLayers(topScoringLayers, { force: true, animate: false, ...paddingOptions });
          } else {
            map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
          }
        }, 10);
        return;
      }

      if (adjustView) {
        setTimeout(() => {
          if (this.setXportingState()) {
            return;
          }
          if (topScoringLayers.length > 1) {
            map.FlyToLayers(topScoringLayers, { force: true, animate: true, ...paddingOptions });
          } else {
            map.FitAllLayers({ force: true, animate: true, ...paddingOptions });
          }
        }, 100);
      }

      this.debounceRefreshMap();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    teamHovered(geo: any): void {
      const homeLayer = this.dmaMarketsHash[geo.key];
      const outermarketLayer =
        this.dmaMarketsHash[geo.key.replace('DMA_Region_Optimum_Sports', 'Outermarket_Optimum_Sports')];
      if (homeLayer) {
        homeLayer.setStyle(this.Theme?.polygons?.light?.rsnHover);
      }
      if (outermarketLayer) {
        outermarketLayer.setStyle(this.Theme?.polygons?.light?.outerMarketHover);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    teamHoveredOut(geo: any): void {
      const homeLayer = this.dmaMarketsHash[geo.key];
      const outermarketLayer =
        this.dmaMarketsHash[geo.key.replace('DMA_Region_Optimum_Sports', 'Outermarket_Optimum_Sports')];
      if (homeLayer) {
        homeLayer.setStyle(this.Theme?.polygons?.light?.rsn);
      }
      if (outermarketLayer) {
        outermarketLayer.setStyle(this.Theme?.polygons?.light?.outerMarket);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rendered(ctx: any): void {
      if (this.hasRendered) {
        return;
      }
      this.$emit('rendered', ctx);
      this.hasRendered = true;
    },
  },
});
