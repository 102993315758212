
import Vue from 'vue';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'SummaryNarrativeFeedbackModal',
  components: { C360Icon },
  props: ['showModal', 'reaction', 'requestId'],
  data: () => ({
    isLoading: false,
    isError: false,
    message: '',
    currentReaction: '',
  }),
  methods: {
    closeModal(): void {
      this.isLoading = true;
      this.sendFeedback({
        rating: this.currentReaction,
        comment: this.message,
        requestId: this.requestId,
      }).then(data => {
        if (data.error) {
          this.isError = true;
        }

        this.isLoading = false;
        this.message = '';
        this.$emit('close', false);
      });
    },
    chooseFeedbackReaction(state): void {
      this.currentReaction = state;
    },
    async sendFeedback(payload): Promise<void> {
      try {
        const response = await this.$store.dispatch('sendCampaignSummaryNarrativeFeedback', payload);
        return response;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  },
  watch: {
    reaction: {
      handler: function (val) {
        if (val) {
          this.currentReaction = val;
        }
      },
      deep: true,
      immediate: true,
    },
  },
});
