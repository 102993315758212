import { render, staticRenderFns } from "./benchmarksChart.vue?vue&type=template&id=474b2186&scoped=true"
import script from "./benchmarksChart.vue?vue&type=script&lang=ts"
export * from "./benchmarksChart.vue?vue&type=script&lang=ts"
import style0 from "./benchmarksChart.vue?vue&type=style&index=0&id=474b2186&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474b2186",
  null
  
)

export default component.exports