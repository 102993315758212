
import Vue from 'vue';
import { C360Icon } from '@c360/ui';
import { format, parse } from 'date-fns';
import { AdvertiserInfo } from '@point/utility-classes/src/advertiserInfo';
import utils from '../../../../util';
import SummaryNarrativeFeedbackModal from './SummaryNarrativeFeedbackModal/SummaryNarrativeFeedbackModal.vue';

export default Vue.extend({
  name: 'CampaignSummaryNarrativeModal',
  components: { C360Icon, SummaryNarrativeFeedbackModal },
  props: ['showModal'],
  data: () => ({
    isLoading: true,
    isError: false,
    campaignSummaryContent: '',
    campaignInsightsContent: '',
    campaignRecommendationsContent: '',
    campaignSummaryErrorContent: '',
    currentCampaignID: null,
    campaignName: '',
    showFeedbackModal: false,
    feedbackReaction: 'up',
    requestId: '',
  }),
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
    formatDate(date: string): string {
      if (!date || date === '' || date === 'undefined') {
        return '';
      }
      try {
        const parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
        return format(parsedDate, 'MMM d, yyyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err);
      }
      return '';
    },
    getCampaignSummaryNarrativeFull(): void {
      this.isLoading = true;
      this.currentCampaignID = this.$route.query?.viewCampaigns;

      if (this.currentCampaignID) {
        this.campaignName = this.selectedCampaign?.FriendlyName || this.selectedCampaign?.name;
        const type = this.tactic;
        let payload = {
          id: this.currentCampaignID,
          advertiser_id: this.advertiserInfo?.id,
          type: type,
        };

        if (type === 'EMAILSI') {
          payload.type = 'SITEIMPACT';
        }

        this.fetchCampaignSummaryNarrativeFull(payload).then(data => {
          if (data.error) {
            this.isError = true;
            this.campaignSummaryErrorContent = `
                There is not sufficient information to generate an AI Summary Narrative at this time.
              `;
          } else {
            this.campaignSummaryContent = data.summary;
            this.campaignInsightsContent = data.insights;
            this.campaignRecommendationsContent = data.recommendations;
            this.requestId = data.requestId;
          }
          this.isLoading = false;
        });
      }
    },
    async fetchCampaignSummaryNarrativeFull(payload): Promise<void> {
      try {
        const response = await this.$store.dispatch('getCampaignSummaryNarrativeFull', payload);
        return response;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
    getCampaignSummaryNarrative(): void {
      this.isLoading = true;
      this.currentCampaignID = this.$route.query?.viewCampaigns;

      if (this.currentCampaignID) {
        this.campaignName = this.selectedCampaign?.FriendlyName || this.selectedCampaign?.name;
        const type = this.tactic;
        let payload = {
          id: this.currentCampaignID,
          type: type,
        };

        if (type === 'CALLTRACKING') {
          payload['advertiser_id'] = this.advertiserInfo?.id;
        }

        if (type === 'EMAILSI') {
          payload.type = 'SITEIMPACT';
        }

        this.fetchCampaignSummaryNarrative(payload).then(data => {
          if (data.error) {
            this.isError = true;
            this.campaignSummaryErrorContent = `
                There is not sufficient information to generate an AI Summary Narrative at this time.
              `;
          } else {
            this.campaignSummaryContent = data;
            this.requestId = data.requestId;
          }
          this.isLoading = false;
        });
      }
    },
    async fetchCampaignSummaryNarrative(payload): Promise<void> {
      try {
        const response = await this.$store.dispatch('getCampaignSummaryNarrative', payload);
        return response;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
    openFeedbackModel(reaction): void {
      this.feedbackReaction = reaction;
      this.showFeedbackModal = true;
    },
    toggleCapmaignNarrativeFeedbackModal(state: boolean, reaction?: string) {
      this.showFeedbackModal = state;

      if (reaction) {
        this.feedbackReaction = reaction;
      }
    },
  },
  computed: {
    campaignRemainingDaysTxt() {
      const campaignRemainingDaysAbsolete = Math.abs(this.campaignRemainingDays) || 0;
      return `${campaignRemainingDaysAbsolete} day${
        campaignRemainingDaysAbsolete > 1 || campaignRemainingDaysAbsolete === 0 ? 's' : ''
      } left`;
    },
    campaignRemainingDays() {
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();
      const endTime = this.selectedCampaign?.End_date ? new Date(this.selectedCampaign.End_date).getTime() : 0;
      const remainingDays = Math.floor((endTime - currentTime) / millisecondsPerDay);
      return remainingDays;
    },
    campaignStartDateFormatted(): string {
      return this.formatDate(this.selectedCampaign?.Start_date) || 'TBA';
    },
    campaignEndDateFormatted(): string {
      return this.formatDate(this.selectedCampaign?.End_date) || 'TBA';
    },
    campaignFlightDates() {
      return `${this.campaignStartDateFormatted} — ${this.campaignEndDateFormatted}`;
    },
    isActive() {
      return this.campaignRemainingDays >= 0;
    },
    selectedCampaign() {
      return this.$store.state.filters?.selectedCampaigns[0];
    },
    advertiserInfo(): AdvertiserInfo | null {
      return this.$store.state?.advertiser?.advertiserInfo?.data || null;
    },
    tactic(): string {
      return this.$route.query?.tab?.toUpperCase() || this.$store.state.customer?.selection?.tab?.toUpperCase();
    },
    isAbleToFetchFullSummary() {
      const feeds = utils.feedSources(this);
      const currentFeed = feeds[0];
      const availableFeedOptions = {
        ZYP: ['DISPLAY', 'PREROLL', 'VIDEO', 'OTT'],
        XANDR: ['DISPLAY', 'PREROLL', 'VIDEO', 'OTT'],
        AMAZON: ['DISPLAY', 'PREROLL', 'VIDEO', 'AUDIO', 'OTT'],
        YAHOO: ['OTT'],
        CADENT: ['OTT'],
        CASTIRON: ['OTT'],
        HAVAS: ['OTT'],
        GROUNDTRUTH: ['GTDISPLAY', 'GTVIDEO'],
        SIMPLIFI: ['DISPLAY', 'PREROLL', 'VIDEO', 'SIMPGEOFENCE'],
        TRITON: ['AUDIO'],
        MAGNITE: ['AUDIO'],
        BEESWAX: ['DISPLAY', 'PREROLL', 'VIDEO', 'SIMPGEOFENCE'],
      };
      const availableProducts = ['GAMDISPLAY', 'GAMVIDEO', 'SEM', 'EMAILSI', 'GOOGLEVIDEO', 'SOCIAL', 'CALLTRACKING'];

      if (
        availableProducts.includes(this.tactic) ||
        (availableFeedOptions[currentFeed] && availableFeedOptions[currentFeed].includes(this.tactic))
      ) {
        return true;
      }

      return false;
    },
  },
  watch: {
    showModal: {
      handler: function (val) {
        if (val && this.$route.query?.viewCampaigns !== this.currentCampaignID) {
          if (this.isAbleToFetchFullSummary) {
            this.getCampaignSummaryNarrativeFull();
          } else {
            this.getCampaignSummaryNarrative();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
});
