
import Vue from 'vue';
import { Theme } from '@point/utility-classes';
import gradstop from 'gradstop';
import MapComponent from '@point/map-component';
import MapCampaignSummaryController from './mapCampaignSummary/mapCampaignSummaryController.vue';
import NoMapData from '../charts/no-data/NoMapData.vue';
import EditModuleBtn from '../charts/buttons/editModuleBtn.vue';
import utils from '../../../util';
import ERRORS from '../../../errors';

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'stationMap',
  components: { MapComponent, NoMapData, MapCampaignSummaryController, EditModuleBtn },
  props: {
    sectionConfig: Object,
    componentConfig: Object,
    title: String,
    theme: Object,
    isExporting: Boolean,
    isExportDynamic: Boolean,
    exportData: Object,
    exportContext: Object,
    componentHeight: Number,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data(): any {
    return {
      polygons: {},
      mapDataLookup: {},
      mapAdData: null,
      geoParams: null,
      loading: true,
      loadedValidData: true,
      hasRequiredData: false,
      debugMap: false,
      mapReady: false,
      legend: false,
      dataCacheKey: null,
      layoutThemes: [],
      hoveredLayer: null,
      hoveredZipText: [],
      exportBackgroundImage: null,
      mapId: null,
      emptyPolygonsResponse: false,
    };
  },
  computed: {
    isPrinting(): boolean {
      return this.$route.query.print === 'true';
    },
    mapRenderCacheKey(): string {
      return `_stationMap_${this.dataCacheKey}_light`;
    },
    mapCacheKey(): string {
      return `_stationMap_${this.dataCacheKey}`;
    },
    mapTilerMapId(): string {
      if (this.isPrinting) {
        return utils.getMapId('printing');
      }
      const { theme }: { theme: Theme } = this.$store.state.customer;
      if (this.layoutThemes?.length === 1) {
        // if only one theme exist
        return utils.getMapId(this.layoutThemes[0]);
      }
      if (this.layoutThemes?.length > 1) {
        // if dark and light exist
        const returnTheme = theme ? this.layoutThemes[1] : this.layoutThemes[0];
        return utils.getMapId(returnTheme);
      }
      return '24753aa3-7a2d-4bb6-9370-e7d657b08efb';
    },
    showStandAloneMapSummary(): boolean {
      return true;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    mapHeight(): number {
      let height = this.componentHeight;
      if (!this.onlySummary && this.hasRequiredData && this.loadedValidData && !this.isXLS) {
        height = this.componentHeight;
      } else if (
        !this.isExporting &&
        !this.onlyMap &&
        this.showStandAloneMapSummary &&
        this.componentConfig.breakpoints.includes('lg12')
      ) {
        height = 270;
      }
      return height;
    },
    onlySummary(): boolean {
      return this.componentConfig.onlySummary;
    },
    onlyMap(): boolean {
      return this.componentConfig.onlyMap;
    },
  },
  watch: {
    '$route.query': function (): void {
      // don't watch the query, as the url may have the new values, but the data in the store is still 'old'
      // setTimeout(this.initMap, 100);
    },
    mapRenderCacheKey: {
      handler(key: string, old: string): void {
        if (old.indexOf('_null_') > 0) {
          return; // ignore the first mounted, before data is available
        }
        setTimeout(() => {
          this.renderPolygons();
        }, 500);
      },
    },
  },
  mounted() {
    this.$store.dispatch('setFetching', { cid: this.componentConfig.cid });
    // only initMap when data is loaded, there may be old data from previous mount
    setTimeout(() => {
      if (!utils.isWaitingOnData(this)) {
        this.initMap();
      }
    }, 1000);
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initMap, true);
  },
  beforeDestroy(): void {
    unwatchDataChanges();
  },
  methods: {
    setXportingState(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map) return;
      // apply exportState if available
      const exportState = this.$store.state.customer?.dashxExportState?.[this.componentConfig?.cid];
      const currentZoom = exportState?.zoomLevel || map.GetZoom();
      if (exportState?.center) {
        map.FlyTo(exportState?.center, currentZoom, { animate: false });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rendered(ctx: any): void {
      if (this.isXLS || this.showStandAloneMapSummary) {
        this.$emit('rendered', ctx);
      }
    },
    initMap(): void {
      if (this.onlySummary) {
        this.loading = false;
        return;
      }

      const selectionKey =
        `${this.$route.query.view || ''} ${this.$route.query.id || ''} ` +
        `${this.$route.query.tab || ''} ${this.$route.query.viewCampaigns || ''} ` +
        `${this.$route.query.daterange || ''} ${this.$route.query.startdate || ''} ` +
        `${this.$route.query.enddate || ''}`;
      this.dataCacheKey = `${this.componentConfig.dataSource}_${selectionKey}`.trim().replace(/[\W_]+/g, '_');

      if (utils.isWaitingOnData(this)) {
        this.loading = true;
        return;
      }

      this.mapAdData = utils.adDataForKey(this, this.componentConfig.dataSource);
      this.geoParams = utils.adDataForKey(this, this.componentConfig.geoParams);

      this.hasRequiredData =
        this.dataCacheKey && this.mapAdData && Array.isArray(this.mapAdData) && this.mapAdData.length > 0;

      if (this.isExporting && this.componentConfig.pretendError) {
        this.hasRequiredData = false;
      }

      if (!this.hasRequiredData) {
        this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
        this.loading = false;
        if (this.componentConfig.showEvenIfNoData) {
          this.debugMap = true;
          this.hasRequiredData = true;
          this.loadedValidData = true;
        }
        setTimeout(() => {
          this.$emit('rendered', { empty: true });
        }, 10);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.mapAdData.forEach((d: any) => {
        this.mapDataLookup[`${d.geoParamKey}`] = d;
      });

      this.loadPolygons();
      this.legend = this.componentConfig.legend;
      this.layoutThemes = this.componentConfig?.themes;
    },
    onMapReady(evt): void {
      const { id, redrawing } = evt;
      const map = this.$refs[id]?.Get();
      this.mapId = id;
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      if (!redrawing) {
        map.ZoomControlsOnRight();
      }

      this.mapReady = true;
      if (this.mapRenderCacheKey !== map.renderCacheKey) {
        setTimeout(() => {
          map.Redraw();
        }, 20);
        setTimeout(() => {
          this.renderPolygons();
        }, 500);
      }
      const mapDiv = document.getElementById('stationMap');
      const resizeObserver = new ResizeObserver(() => {
        map.Resize();
      });

      resizeObserver.observe(mapDiv);
    },
    debounceMapMoved(): void {
      this.drawBackground();
      this.saveExportState();
    },
    saveExportState(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map) return;
      const zoomLevel = map.GetZoom();
      const center = map.GetCenter();
      utils.updateExportState(this.componentConfig.cid, 'zoomLevel', zoomLevel);
      utils.updateExportState(this.componentConfig.cid, 'center', center);
    },
    drawBackground() {
      const mapEl = this.$refs[this.mapId]?.$el;
      const map = this.$refs[this.mapCacheKey]?.Get();
      const zoom = map.GetZoom() - 1; // why do we need the -1 ???
      const center = map.GetCenter();
      const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
      const tileBackground = tileBackgroundList[0] as HTMLElement;
      // const bounds = map.GetBounds();
      // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      var img = new Image();
      img.addEventListener(
        'load',
        () => {
          // only if we are exporting, we need to set the background image and hide the map
          if (this.isExporting) {
            this.exportBackgroundImage = staticMapByCenter;
            tileBackground.style.display = 'none';
          }
          setTimeout(() => {
            this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
            this.$emit('rendered', { empty: false });
          }, 1000);
        },
        false,
      );
      img.src = staticMapByCenter;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitRendered(map: any, mapId: string, retries = 5): void {
      if (this.isExporting) {
        setTimeout(() => {
          const mapEl = this.$refs[mapId]?.$el;
          if (!mapEl) {
            // eslint-disable-next-line no-console
            console.error('can not export, no map element');
            if (retries > 0) {
              setTimeout(() => {
                this.emitRendered(map, mapId, retries - 1);
              }, 500);
            } else {
              setTimeout(() => {
                this.$emit('rendered', { empty: true });
              }, 10);
            }
            return;
          }
          const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
          if (!tileBackgroundList || tileBackgroundList.length === 0) {
            // eslint-disable-next-line no-console
            console.error('StationMap, can not export, no map background');
            if (retries > 0) {
              setTimeout(() => {
                this.emitRendered(map, mapId, retries - 1);
              }, 500);
            } else {
              setTimeout(() => {
                this.$emit('rendered', { empty: true });
              }, 10);
            }
            return;
          }
          const tileBackground = tileBackgroundList[0] as HTMLElement;

          const zoom = map.GetZoom() - 1; // why do we need the -1 ???
          const center = map.GetCenter();
          // const bounds = map.GetBounds();
          // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
          const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
          var img = new Image();
          img.addEventListener(
            'load',
            () => {
              this.exportBackgroundImage = staticMapByCenter;
              tileBackground.style.display = 'none';
              setTimeout(() => {
                this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
                this.$emit('rendered', { empty: false });
              }, 1000);
            },
            false,
          );
          img.src = staticMapByCenter;
        }, 1000);
      } else {
        setTimeout(() => {
          this.$emit('rendered', { empty: false });
        }, 1000);
      }
    },
    loadPolygons(): void {
      if (!this.geoParams || this.geoParams.length === 0) {
        this.loading = false;
        this.loadedValidData = false;
        // eslint-disable-next-line no-console
        console.log('loadPolygons', 'empty geoParams');
        this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
        // we may have a summary, so let's wait and emit 'empty' is nothing else rendered
        setTimeout(() => {
          this.$emit('rendered', { empty: true });
        }, 5000);
        return;
      }

      this.loadedValidData = true;
      this.loading = true;

      this.polygons = {};
      this.$store
        .dispatch('getPolygons', { geoParams: this.geoParams })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          this.loading = false;
          if (!data) {
            // eslint-disable-next-line no-console
            console.log('getPolygons', 'no polygon response');
            this.emptyPolygonsResponse = true;
          } else {
            Object.keys(data).forEach((k: string) => {
              const poly = data[k];
              if (k === 'LocationNotFound') {
                if (!this.isExporting) {
                  // eslint-disable-next-line no-console
                  console.error('polygons not found', poly);
                }
                return;
              }
              if (poly.WKT_P100) {
                this.polygons[poly.DMA_CODE] = poly;
              }
            });
            if (Object.keys(this.polygons).length === 0) {
              // this.loadedValidData = false;
              // eslint-disable-next-line no-console
              console.log('getPolygons', ERRORS.NO_POLYGONS);
              this.emptyPolygonsResponse = true;
            }
          }
          setTimeout(() => {
            // leave some breathing room, the recycled map need to be moved back into the current DOM
            this.renderPolygons();
          }, 250);
        })
        .catch((error: Error) => {
          this.loading = false;
          // this.loadedValidData = false;
          // this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          console.error('getPolygons fetch error', error);
          this.emptyPolygonsResponse = true;
        });
    },
    renderPolygons(): void {
      if (!this.mapReady || !this.Theme) {
        return;
      }
      const nameKey = this.componentConfig.geoNameKey;
      const { dmaGradient } = this.Theme.polygons?.light;

      const gradient = gradstop({
        stops: 101,
        inputFormat: 'hex',
        colorArray: dmaGradient || ['#B1C5CE', '#03A9F4', '#01579B'],
      });

      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      this.emitRendered(map, this.mapCacheKey);

      map.ClearMap();
      const hostLayer = map.CreateLayer('polygons');
      let hasData = false;


      // set max polygon to 1000 to release DASH-5123 before big map refactor DASH-5059
      // Apply different limits for exporting using old method (!isPrinting)
      const maxPolygonLimit = this.isExporting && !this.isPrinting ? 100 : 1000;

      let polygonsAmount = 0;

      if (!this.emptyPolygonsResponse) {
        Object.keys(this.polygons).forEach((key: string) => {

          polygonsAmount++;
          if (polygonsAmount >= maxPolygonLimit) return;

          const poly = this.polygons[key];
          let data = this.mapDataLookup[key];
          // console.log(key, poly, this.mapDataLookup[key], this.mapDataLookup);
          if (!data) {
            // temp
            data = { Visits: 0, Aired: 0, SpendVal: 0, [nameKey]: 'n/a' };
          }
          const polyName = data ? data[nameKey] : null;
          if (!data || !polyName) {
            return;
          }

          const layer = map.AddToLayer(hostLayer);
          map.SetWKT(layer, poly.WKT_P100, true);

          let rate = parseFloat(data.Visits);
          if (Number.isNaN(rate) || rate < 0) {
            rate = 0;
          } else if (rate > 100) {
            rate = 100;
          }
          const zIndex = Math.floor(0.99 * rate);
          layer.setZIndex(zIndex + 1);
          const color = gradient[zIndex];
          const color2 = gradient[50];

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const style: any = { ...this.Theme.polygons?.light.dma, color: color2, fillColor: color };

          // const hoverStyle: any = { ...this.Theme.polygons?.light.dmaHover, color: color2, fillColor: color };

          layer.setStyle(style);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let layerPopup: any = null;
          layer
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .on('mouseover', (e: any) => {
              this.hoveredLayer = data;
              // layer.setStyle(hoverStyle);
              this.hoveredZipText[0] = '';
              this.hoveredZipText[1] = '';
              this.hoveredZipText[0] += `${polyName}`;
              this.hoveredZipText[1] += `${utils.formatNumberWithCommas(data.Visits)}`;
              this.hoveredZipText[2] = 'visits,';
              this.hoveredZipText[3] = `${utils.formatNumberWithCommas(data.Aired)}`;
              this.hoveredZipText[4] = 'aired';
              if (data.SpendVal) {
                this.hoveredZipText[5] = `,${utils.formatNumberWithCommas(data.SpendVal)}`;
                this.hoveredZipText[6] = 'spend';
              }
              setTimeout(() => {
                const template = this.$refs.mapToolTip?.innerHTML || '';
                layerPopup = map.CreatePopup(template, e.latlng, { closeButton: false, offset: [2, 10] });
              }, 500);
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .on('mousemove', (e: any) => {
              if (layerPopup) {
                layerPopup.setLatLng(e.latlng);
              }
            })
            .on('mouseout', () => {
              layer.setStyle(style);
              if (layerPopup) {
                map.ClosePopup(layerPopup);
                layerPopup = null;
              }
            });
          hasData = true;
        });
        if (!hasData) {
          return;
        }
      }
      map.SetRenderCacheKey(this.mapRenderCacheKey);
      setTimeout(() => {
        if (!this.emptyPolygonsResponse) {
          map.FitAllLayers({ force: true, animate: false });
        } else {
          map.SetView([40, -100], 4);
        }
        this.setXportingState();
      }, 100);
    },
  },
});
