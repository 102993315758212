
import Vue from 'vue';
import utils from '../../../util';
import ChartTooltip from '../charts/tooltip/chartTooltip.vue';
import { Tooltip } from '../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import { getCssVar } from '@c360/ui';

export default Vue.extend({
  inheritAttrs: false,
  components: {
    ChartTooltip,
  },
  name: 'progressBar',
  props: {
    sectionConfig: Object,
    componentConfig: Object,
    values: Object,
    barHeight: String,
    metricFont: Object,
    barColor: String,
    hideText: Boolean,
    standAlone: Boolean,
    oneRow: Boolean,
    inTable: Boolean,
    isExporting: Boolean,
    summaryModule: Boolean,
    showTextInSameRow: Boolean,
    dateRangeSelected: String,
    exportData: Object,
    bothMetricsAndProgBar: Boolean,
  },
  data() {
    return {
      goalValue: null,
      dateRangeValue: '',
      isActivePrevBtn: false,
      isActiveNextBtn: true,
      metricOverTotal: null,
    };
  },
  created() {
    this.goalValue = this.formatNumber(this.values.totalValue);
    this.dateRangeValue = this.dateRangeSelected;
    this.metricOverTotal = this.calcMetricOverTotal();
  },
  computed: {
    goalValueToDisplay(): string {
      if (!this.isExporting) return this.goalValue;
      const exportState = this.getExportState();
      const cfg = this.exportData?.userState;
      const selectedRange = exportState?.selectedRangeFormattedName || cfg?.selectedRangeFormattedName;
      if (selectedRange === 'All Time') {
        return this.formatNumber(this.values.originImpressionGoal);
      }
      return this.formatNumber(this.values.totalValue);
    },
    dateRangeValueToDisplay(): string {
      if (!this.isExporting) return this.dateRangeValue;
      const exportState = this.getExportState();
      const cfg = this.exportData?.userState;
      const selectedRange = exportState?.selectedRangeFormattedName || cfg?.selectedRangeFormattedName;
      return selectedRange ?? '';
    },
    metricValueClasses(): string[] {
      let classes: string[] = [];
      classes.push(this.metricFont?.size ? `text-${this.metricFont.size}` : 'text-subtitle-1');
      classes.push(this.metricFont?.weight ? `font-weight-${this.metricFont.weight}` : 'font-weight-medium');
      if (this.summaryModule) classes = ['c360-text-body'];
      return classes;
    },
    progressContainerStyles(): { background: string; height: string } {
      return {
        background: '#eeeeee',
        height: this.barHeight ? this.barHeight : '15px',
      };
    },
    progressStyles(): { background: string; width: string } {
      const exportState = this.getExportState();
      const selectedImpressionPercent = exportState?.impressionPercentForSelectedRange || this.exportData?.userState?.impressionPercentForSelectedRange;
      // separate styling for progress bar from summary module
      if (this.summaryModule) {
        const styles = {
          background: 'var(--c360-gradient-accent)',
          width: this.isExporting
            ? selectedImpressionPercent
            : this.metricOverTotal,
        };
        if (this.metricOverTotal < 100) {
          styles.borderRadius = '16px';
          styles.border = '3px solid white';
        }
        return styles;
      }

      const styles = {
        background: this?.componentConfig?.progressBarColor,
        width: this.metricOverTotal,
      };
      styles.borderRadius = '16px';
      styles.border = '2px solid white';
      if (!this?.componentConfig?.progressBarColor) {
        styles.background = getCssVar('--c360-gradient-accent');
      }
      if (this.metricOverTotal > 100) {
        styles.border = 'none';
      }
      return styles;
    },
    primaryColor(): { color: string } {
      return { color: getCssVar('--c360-gradient-start') };
    },
    percentLabel(): string {
      let label = `${this.metricOverTotal} of ${this.inTable ? 'total' : 'Goal'}`;
      // show metric value if metric is a percentage itself
      if (utils.percentageMetrics.includes(this.values.sortKey)) {
        label = this.values.metricValue;
      }
      return label;
    },
    isGamTactic(): boolean {
      if (this.isExporting) {
        return (
          this.$store.state.customer.exportingData.tab.toLowerCase() === 'gamdisplay' ||
          this.$store.state.customer.exportingData.tab.toLowerCase() === 'gamvideo'
        );
      } else {
        return (
          this.$store.state.customer.currentNavTab.toLowerCase() === 'gamdisplay' ||
          this.$store.state.customer.currentNavTab.toLowerCase() === 'gamvideo'
        );
      }
    },
    isInSummaryModule(): boolean {
      return this.componentConfig?.cid?.toLowerCase().includes('map');
    },
    isShowImpressionGoal(): boolean {
      if (!this.isInSummaryModule) return true;
      return this.values.totalValue > 100;
    },
    isShowDateRangeButtons(): boolean {
      return this.values.totalValue !== this.values.originImpressionGoal;
    },
  },
  methods: {
    getExportState() {
      return this.$store.state.customer?.dashxExportState?.[this.componentConfig?.cid];
    },
    formatNumber(num: string): string {
      if (typeof num === 'string' && num.split('').includes('$')) return num;
      return utils.formatNumberWithCommas(parseInt(num));
    },
    getTooltips(type: string): Array<Tooltip> {
      // digital video hack
      if (this.componentConfig && this.componentConfig.dataSource === 'VIDEO' && type?.toLowerCase() === 'impressions')
        type = 'digitalvideoimp';
      return [utils.getTooltipsFromMetric(type)];
    },
    formatValueEstimate(num: number | string) {
      return utils.formatValueEstimate(num);
    },
    showNextDateRange() {
      if (this.isShowDateRangeButtons) {
        this.metricOverTotal = this.calcMetricOverTotal(this.values.originImpressionGoal);
        this.isActivePrevBtn = true;
        this.isActiveNextBtn = false;
        this.goalValue = this.formatNumber(this.values.originImpressionGoal);
        this.dateRangeValue = 'All Time';
        if (!this.isExporting) {
          const exportState = this.$store.getters.exportState || {};
          exportState.selectedRangeFormattedName = this.dateRangeValue;
          this.$store.dispatch('setExportState', exportState);
        }
        this.$emit('switchDateRange', {
          totalValue: this.values.originImpressionGoal,
          dateRangeValue: this.dateRangeValue,
        });
      }
    },
    showPrevDateRange() {
      if (this.isShowDateRangeButtons) {
        this.metricOverTotal = this.calcMetricOverTotal();
        this.isActiveNextBtn = true;
        this.isActivePrevBtn = false;
        this.goalValue = this.formatNumber(this.values.totalValue);
        this.dateRangeValue = this.dateRangeSelected;
        if (!this.isExporting) {
          const exportState = this.$store.getters.exportState || {};
          exportState.selectedRangeFormattedName = this.dateRangeSelected;
          this.$store.dispatch('setExportState', exportState);
        }
        this.$emit('switchDateRange', { totalValue: this.values.totalValue, dateRangeValue: this.dateRangeValue });
      }
    },
    calcMetricOverTotal(originImpressionGoal): string {
      let metric = this.values.metricValue;
      let total = this.values.totalValue || 0;
      let percent;

      if (originImpressionGoal) {
        total = originImpressionGoal;
      }

      if (!this.values.percent) {
        // calculate percent if not given
        if (typeof metric === 'string') {
          metric = parseFloat(this.values.metricValue.replace(/[!@#$%^&*]/g, ''));
        }
        if (typeof total === 'string') {
          total = parseFloat(this.values.totalValue.replace(/[!@#$%^&*]/g, ''));
        }

        if (total === 0) {
          percent = metric === 0 ? '0%' : '100%';
        } else {
          percent = `${((metric / total) * 100).toFixed(0)}%`;
        }
      } else {
        percent = this.values.percent;
      }

      return percent;
    },
  },
  watch: {
    values: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler(newVal: any, oldVal: any): void {
        if (this.componentConfig?.cid?.toLowerCase().includes('performance')) {
          if (oldVal?.metricValue !== newVal?.metricValue) {
            this.metricOverTotal = this.calcMetricOverTotal();
          }
        }
      },
      deep: true,
    },
  },
});
