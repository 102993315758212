
import Vue from 'vue';
import { Theme } from '@point/utility-classes';
import gradstop from 'gradstop';
import MapComponent from '@point/map-component';
import MapCampaignSummaryController from './mapCampaignSummary/mapCampaignSummaryController.vue';
import NoMapData from '../charts/no-data/NoMapData.vue';
import EditModuleBtn from '../charts/buttons/editModuleBtn.vue';
import utils from '../../../util';
import ERRORS from '../../../errors';
import { ThemeConfig } from '../../../plugins/vuetify/themes/variables';
import debounce from 'lodash.debounce';

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'genericMap',
  components: { MapComponent, NoMapData, MapCampaignSummaryController, EditModuleBtn },
  props: {
    sectionConfig: Object,
    componentConfig: Object,
    title: String,
    isExporting: Boolean,
    isExportDynamic: Boolean,
    exportData: Object,
    exportContext: Object,
    componentHeight: Number,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data(): any {
    return {
      polygons: {},
      mapDataLookup: {},
      mapAdData: null,
      geoParams: null,
      loading: true,
      loadedValidData: true,
      hasRequiredData: false,
      hasRendered: false,
      mapExportBackground: null,
      mapReady: false,
      legend: false,
      dataCacheKey: null,
      layoutThemes: [],
      hoveredLayer: ['', ''],
      hoveredZipText: [],
      exportBackgroundImage: null,
      mapId: null,
      emptyPolygonsResponse: false,
    };
  },
  computed: {
    isPrinting(): boolean {
      return this.$route.query.print === 'true';
    },
    geoPerformancePayload(): any {
      // If params are not present in the url (like in shared view) find them in the store
      const id = this.$route.query?.id || this.$store.state.customer?.selection?.advertiserId || '';
      const daterange = this.$route.query?.daterange || this.$store.state.customer?.selection?.daterange || 'alltime';
      const startdate =
        this.$route.query?.campaignstartdate ||
        utils.formatDate(this.$store.state.customer?.selection?.startdate) ||
        '';
      const enddate =
        this.$route.query?.campaignenddate || utils.formatDate(this.$store.state.customer?.selection?.enddate) || '';
      const campaignsIds = this.$route.query?.viewCampaigns?.split(',');
      const type = this.$route.query?.tab?.toUpperCase() || this.$store.state.customer?.selection?.tab?.toUpperCase();

      const campaigns = campaignsIds?.map(c => {
        return {
          id: c,
          type: type,
        };
      });

      return {
        daterange,
        advertiserId: id,
        campaigns,
        startdate: startdate,
        enddate: enddate,
      };
    },
    mapRenderCacheKey(): string {
      return `_genericMap_${this.dataCacheKey}_light`;
    },
    mapCacheKey(): string {
      return `_genericMap_${this.dataCacheKey}`;
    },
    mapTilerMapId(): string {
      if (this.isPrinting) {
        return utils.getMapId('printing');
      }
      const { theme }: { theme: Theme } = this.$store.state.customer;
      if (this.layoutThemes?.length === 1) {
        // if only one theme exist
        return utils.getMapId(this.layoutThemes[0]);
      }
      if (this.layoutThemes?.length > 1) {
        const returnTheme = theme ? this.layoutThemes[1] : this.layoutThemes[0];
        return utils.getMapId(returnTheme);
      }
      return '24753aa3-7a2d-4bb6-9370-e7d657b08efb';
    },
    showMapSummary(): boolean {
      return true;
    },
    showStandAloneMapSummary(): boolean {
      return true; // doesn not look right yet
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    configID(): string {
      return this.componentConfig.id || this.componentConfig.cid;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    onlySummary(): boolean {
      return this.componentConfig.onlySummary;
    },
    onlyMap(): boolean {
      return this.componentConfig.onlyMap;
    },
    demographicsOnly(): boolean {
      return this.componentConfig.customStyle === 'demographicsOnly';
    },
    mapComponentHeight(): number {
      if (typeof this.componentConfig.size?.height === 'number') {
        return this.componentConfig.size?.height;
      }
      if (typeof this.componentHeight === 'number') {
        return this.componentConfig;
      }
      return 485;
    },
  },
  watch: {
    mapRenderCacheKey: {
      handler(key: string, old: string): void {
        if (old.indexOf('_null_') > 0) {
          return; // ignore the first mounted, before data is available
        }
        setTimeout(() => {
          this.renderPolygons();
        }, 500);
      },
    },
    '$route.query.viewCampaigns': {
      handler(): void {
        if (!this.isExporting) {
          // We need to wait for BE Ticket DASH-5431 to implement geo performance endpoint
          // this.getDebouncedGeoPerformance();
        }
      },
      immediate: false,
    },
  },
  mounted() {
    this.$store.dispatch('setFetching', { cid: this.componentConfig.cid });
    // only initMap when data is loaded, there may be old data from previous mount
    setTimeout(() => {
      if (!utils.isWaitingOnData(this)) {
        this.initMap();
      }
    }, 1000);
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initMap, true);
  },
  beforeDestroy(): void {
    unwatchDataChanges();
  },
  methods: {
    setXportingState(): boolean {
      const map = this.$refs[this.mapCacheKey]?.Get();
      console.log('map', map);
      if (!map) return;
      const exportState = this.$store.state.customer?.dashxExportState?.[this.componentConfig.cid];
      const currentZoom = exportState?.zoomLevel || map.GetZoom();
      console.log('exportState',this.componentConfig.cid, exportState, currentZoom);
      if (exportState?.center) {
        map.FlyTo(exportState?.center, currentZoom, { animate: false });
        return true;
      }
      return false;
    },
    saveExportState(): void {
      const map = this.$refs[this.mapCacheKey]?.Get();
      if (!map) return;
      const zoomLevel = map.GetZoom();
      const center = map.GetCenter();
      utils.updateExportState(this.componentConfig.cid, 'zoomLevel', zoomLevel);
      utils.updateExportState(this.componentConfig.cid, 'center', center);
    },
    initMap(): void {
      if (this.onlySummary) {
        this.loading = false;
        return;
      }

      const selectionKey =
        `${this.$route.query.view || ''} ${this.$route.query.id || ''} ` +
        `${this.$route.query.tab || ''} ${this.$route.query.viewCampaigns || ''} ` +
        `${this.$route.query.daterange || ''} ${this.$route.query.startdate || ''} ` +
        `${this.$route.query.enddate || ''}`;
      this.dataCacheKey = `${this.componentConfig.dataSource}_${selectionKey}`.trim().replace(/[\W_]+/g, '_');

      if (utils.isWaitingOnData(this)) {
        this.loading = true;
        return;
      }

      this.mapAdData = utils.adDataForKey(this, this.componentConfig.dataSource);
      this.geoParams = utils.adDataForKey(this, this.componentConfig.geoParams);

      this.hasRequiredData =
        this.dataCacheKey && this.mapAdData && Array.isArray(this.mapAdData) && this.mapAdData.length > 0;

      if (this.isExporting && this.componentConfig.pretendError) {
        this.hasRequiredData = false;
      }

      if (!this.hasRequiredData) {
        this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
        this.loading = false;
        setTimeout(() => {
          // eslint-disable-next-line no-console
          console.log('empty: !hasRequiredData');
          this.$emit('rendered', { empty: true });
        }, 10);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      for (const d of this.mapAdData as any) {
        if (this.componentConfig.hidePolygonsWithLessThan) {
          const impressionPercent = parseFloat(d.ImpressionPercent);
          if (impressionPercent < this.componentConfig.hidePolygonsWithLessThan) {
            // for now we dont want to limit the amount of polygons with hidePolygonsWithLessThan
            // continue;
          }
        }
        this.mapDataLookup[`${d.geoParamKey}`] = d;
      }

      this.loadPolygons();
      this.legend = this.componentConfig.legend;
      this.layoutThemes = this.componentConfig?.themes;
    },
    onMapReady(evt): void {
      const { id, redrawing } = evt;
      const map = this.$refs[id]?.Get();
      this.mapId = id;
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly 0x02', map);
        return;
      }
      if (!redrawing) {
        map.ZoomControlsOnRight();
      }
      this.mapReady = true;
      if (this.mapRenderCacheKey !== map.renderCacheKey) {
        setTimeout(() => {
          map.Redraw();
        }, 20);
        setTimeout(() => {
          this.renderPolygons();
        }, 500);
      }
      const mapDiv = document.getElementById('genericMap');
      const resizeObserver = new ResizeObserver(() => {
        map.Resize();
      });

      resizeObserver.observe(mapDiv);
    },
    getDebouncedGeoPerformance: debounce(function (): void {
      this.getGeoPerformance();
    }, 1000),
    async getGeoPerformance(): Promise<void> {
      try {
        const { data } = await this.$store.dispatch('performance/getGeoPerformance', this.geoPerformancePayload);

        if (data) {
          // Right now we have missing fields to replace SEM.GeoPerformanceImpression with the new endpoint performance/geo-performance
          // Ticket DASH-5431 created for BE.
          // this.initMap();
        }
      } catch (error) {
        console.log(error);
      }
    },
    debounceMapMoved(): void {
      clearTimeout(this.onMapMovedTimer);
      this.drawBackground();
      this.saveExportState();
      this.onMapMovedTimer = setTimeout(() => {
        // console.log('map end moved');
      }, 500);
    },
    drawBackground() {
      const mapEl = this.$refs[this.mapId]?.$el;
      const map = this.$refs[this.mapCacheKey]?.Get();
      const zoom = map.GetZoom() - 1; // why do we need the -1 ???
      const center = map.GetCenter();
      // const bounds = map.GetBounds();
      // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      this.mapExportBackground = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
      var img = new Image();
      img.addEventListener('error', () => {
        setTimeout(() => {
          if (this.hasRendered) {
            return;
          }
          this.$emit('rendered', { empty: true, from: 'img.error' });
          this.hasRendered = true;
        }, 100);
      });
      img.addEventListener('load', () => {
        this.exportBackgroundImage = this.mapExportBackground;
        setTimeout(() => {
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          if (this.hasRendered) {
            return;
          }
          this.$emit('rendered', { empty: false, from: 'img.load' });
          this.hasRendered = true;
        }, 100);
      });
      img.src = this.mapExportBackground;

      // debug: assume image has loaded and load event didn't fire
      setTimeout(() => {
        if (this.hasRendered) {
          return;
        }
        this.exportBackgroundImage = this.mapExportBackground;
        setTimeout(() => {
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          if (this.hasRendered) {
            return;
          }
          this.$emit('rendered', { empty: false, from: 'img.force' });
          this.hasRendered = true;
        }, 100);
      }, 5000);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitRendered(map: any, mapId: string, retries = 5): void {
      if (this.hasRendered) {
        return;
      }
      if (this.isExporting) {
        setTimeout(() => {
          if (this.hasRendered || this.mapExportBackground) {
            return;
          }
          const mapEl = this.$refs[mapId]?.$el;
          if (!mapEl) {
            // eslint-disable-next-line no-console
            console.error('can not export, no map element', retries);
            if (retries > 0) {
              setTimeout(() => {
                if (this.hasRendered) {
                  return;
                }
                this.emitRendered(map, mapId, retries - 1);
              }, 500);
            } else {
              setTimeout(() => {
                if (this.hasRendered) {
                  return;
                }
                // eslint-disable-next-line no-console
                console.log('empty: no map element');
                this.$emit('rendered', { empty: true });
                this.hasRendered = true;
              }, 10);
            }
            return;
          }
          // const tileBackgroundList = mapEl.getElementsByClassName('leaflet-tile-pane');
          const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
          if (!tileBackgroundList || tileBackgroundList.length === 0) {
            // eslint-disable-next-line no-console
            console.error('GenericMap, can not export, no map background', retries, mapId);
            if (retries > 0) {
              setTimeout(() => {
                if (this.hasRendered) {
                  return;
                }
                this.emitRendered(map, mapId, retries - 1);
              }, 500);
            } else {
              setTimeout(() => {
                if (this.hasRendered) {
                  return;
                }
                // eslint-disable-next-line no-console
                console.log('empty: no map background');
                this.$emit('rendered', { empty: true });
                this.hasRendered = true;
              }, 10);
            }
            return;
          }
          const tileBackground = tileBackgroundList[0] as HTMLElement;

          const zoom = map.GetZoom() - 1; // why do we need the -1 ???
          const center = map.GetCenter();
          // const bounds = map.GetBounds();
          // const staticMapByBounds = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
          this.mapExportBackground = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
          var img = new Image();
          img.addEventListener('error', () => {
            setTimeout(() => {
              if (this.hasRendered) {
                return;
              }
              this.$emit('rendered', { empty: true, from: 'img.error' });
              this.hasRendered = true;
            }, 100);
          });
          img.addEventListener('load', () => {
            this.exportBackgroundImage = this.mapExportBackground;
            tileBackground.style.display = 'none';
            setTimeout(() => {
              this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
              if (this.hasRendered) {
                return;
              }
              this.$emit('rendered', { empty: false, from: 'img.load' });
              this.hasRendered = true;
            }, 100);
          });
          img.src = this.mapExportBackground;

          // debug: assume image has loaded and load event didn't fire
          setTimeout(() => {
            if (this.hasRendered) {
              return;
            }
            this.exportBackgroundImage = this.mapExportBackground;
            tileBackground.style.display = 'none';
            setTimeout(() => {
              this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
              if (this.hasRendered) {
                return;
              }
              this.$emit('rendered', { empty: false, from: 'img.force' });
              this.hasRendered = true;
            }, 100);
          }, 5000);
        }, 100);
      } else {
        setTimeout(() => {
          if (this.hasRendered) {
            return;
          }
          this.$emit('rendered', { empty: false, from: 'not exporting' });
          this.hasRendered = true;
        }, 100);
      }
    },
    loadPolygons(): void {
      if (!this.geoParams || this.geoParams.length === 0) {
        this.loading = false;
        this.loadedValidData = false;
        // eslint-disable-next-line no-console
        console.log('loadPolygons', 'empty geoParams');
        this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
        // we may have a summary, so let's wait and emit 'empty' is nothing else rendered
        setTimeout(() => {
          if (this.hasRendered) {
            return;
          }
          this.$emit('rendered', { empty: true });
          this.hasRendered = true;
        }, 5000);
        return;
      }
      this.loading = true;
      this.loadedValidData = true;
      this.polygons = {};

      this.$store
        .dispatch('getPolygons', { geoParams: this.geoParams })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          this.loading = false;
          if (!data) {
            // eslint-disable-next-line no-console
            console.log('getPolygons: Empty polygon response');
            this.emptyPolygonsResponse = true;
          } else {
            Object.keys(data).forEach((k: string) => {
              const poly = data[k];
              if (k === 'LocationNotFound') {
                if (!this.isExporting) {
                  // eslint-disable-next-line no-console
                  console.log('polygons not found', poly);
                }
                return;
              }
              if (poly.WKT_P100) {
                this.polygons[k] = poly;
              }
            });
            if (Object.keys(this.polygons).length === 0) {
              // eslint-disable-next-line no-console
              console.log('getPolygons', ERRORS.NO_POLYGONS);
              this.emptyPolygonsResponse = true;
            }
          }
          setTimeout(() => {
            // leave some breathing room, the recycled map need to be moved back into the current DOM
            this.renderPolygons();
          }, 250);
        })
        .catch((error: Error) => {
          this.loading = false;
          // this.loadedValidData = false;
          // this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error('getPolygons fetch error', error);
          this.$store.dispatch('setFetching', { cid: this.componentConfig.cid, completed: true });
          this.emptyPolygonsResponse = true;
        });
    },
    renderPolygons(): void {
      if (!this.mapReady || !this.Theme || this.loading) {
        setTimeout(this.renderPolygons, 250);
        return;
      }

      const map = this.$refs[this.mapCacheKey]?.Get();

      if (!this.$refs[this.mapCacheKey]) {
        setTimeout(this.renderPolygons, 250);
        // console.log('waiting for map element...', this.mapCacheKey);
        return;
      }

      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly 0x03', map, this.mapCacheKey);
        return;
      }

      const theme: ThemeConfig = this.Theme;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const polygonConfig: any = theme?.polygons?.light;

      const nameKey = this.componentConfig.geoNameKey;

      const gradient = gradstop({
        stops: 101,
        inputFormat: 'hex',
        colorArray: polygonConfig.dmaGradient || ['#B1C5CE', '#03A9F4', '#01579B'],
      });

      map.ClearMap();
      const hostLayer = map.CreateLayer('polygons');
      let hasData = false;

      // set max polygon to 1000 to release DASH-5123 before big map refactor DASH-5059
      // Apply different limits for exporting using old method (!isPrinting)
      const maxPolygonLimit = this.isExporting && !this.isPrinting ? 100 : 1000;
      let polygonsAmount = 0;

      if (!this.emptyPolygonsResponse) {
        Object.keys(this.polygons).forEach((key: string) => {
          polygonsAmount++;
          if (polygonsAmount >= maxPolygonLimit) return;

          const poly = this.polygons[key];
          const data = this.mapDataLookup[key];
          if (!data) {
            return;
          }
          const polyName = data[nameKey];
          if (!polyName) {
            return;
          }

          const layer = map.AddToLayer(hostLayer);
          map.SetWKT(layer, poly.WKT_P100, true);

          let rate = parseFloat(data.ImpressionPercent);
          if (Number.isNaN(rate) || rate < 0) {
            rate = 0;
          } else if (rate > 100) {
            rate = 100;
          }
          const zIndex = Math.floor(0.99 * rate);
          layer.setZIndex(zIndex + 1);
          const color = gradient[zIndex];
          const color2 = gradient[50];

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const style: any = { ...this.Theme.polygons?.dma, color: color2, fillColor: color };

          layer.setStyle(style);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let layerPopup: any = null;
          layer
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .on('mouseover', (e: any) => {
              this.hoveredLayer = data;
              this.hoveredZipText[0] = '';
              this.hoveredZipText[1] = '';
              this.hoveredZipText[0] += `${polyName}`;
              this.hoveredZipText[1] += `Imp %: ${rate.toFixed(2)}%`;

              setTimeout(() => {
                const template = this.$refs.mapToolTip?.innerHTML || '';
                layerPopup = map.CreatePopup(template, e.latlng, { closeButton: false, offset: [2, 10] });
              }, 500);
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .on('mousemove', (e: any) => {
              if (layerPopup) {
                layerPopup.setLatLng(e.latlng);
              }
            })
            .on('mouseout', () => {
              layer.setStyle(style);
              if (layerPopup) {
                map.ClosePopup(layerPopup);
                layerPopup = null;
              }
            });
          hasData = true;
        });

        if (!hasData && !this.showMapSummary) {
          setTimeout(() => {
            if (this.hasRendered) {
              return;
            }
            // eslint-disable-next-line no-console
            console.log('empty: no map data', Object.keys(this.polygons));
            this.$emit('rendered', { empty: true });
            this.hasRendered = true;
          }, 10);
          return;
        }
      }

      map.SetRenderCacheKey(this.mapRenderCacheKey);
      let paddingOptions = { paddingTopLeft: [20, 20], paddingBottomRight: [20, 20] };
      if (!this.onlyMap) {
        paddingOptions = { paddingTopLeft: [400, 20], paddingBottomRight: [20, 20] };
      }
      if (map.initialized) {
        if (!this.emptyPolygonsResponse) {
          if (this.setXportingState()) {
            return;
          }
          map.FlyToAllLayers();
          setTimeout(() => {
            map.FitAllLayers({ force: true, animate: false });
          }, 100);
        }

        setTimeout(() => {
          const centeredByExportState = this.setXportingState();
          if (!centeredByExportState) {
            if (!this.emptyPolygonsResponse) {
              map.FitAllLayers({ force: true, duration: 0.3, ...paddingOptions });
            } else {
              map.SetView([40, -100], 4);
            }
          }

          setTimeout(() => {
            if (this.hasRendered) {
              return;
            }
            this.emitRendered(map, this.mapCacheKey);
          }, 500);
        }, 100);
      } else {
        const centeredByExportState = this.setXportingState();
        if (!centeredByExportState) {
          if (!this.emptyPolygonsResponse) {
            map.FitAllLayers({ force: true, animate: false, ...paddingOptions });
          } else {
            map.SetView([40, -100], 4);
          }
        }

        setTimeout(() => {
          if (this.hasRendered) {
            return;
          }
          this.emitRendered(map, this.mapCacheKey);
        }, 500);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rendered(ctx: any): void {
      if (this.hasRendered) {
        return;
      }
      this.$emit('rendered', { ...ctx, from: '@rendered' });
      this.hasRendered = true;
    },
  },
});
